<div class="modal-content-new">
    <div class="modal-header subpage">
        <h4 class="modal-title" id="modal-basic-title">
            <button type="button" (click)="modal.close('Ok click')" class="close" class="close"
                data-dismiss="modal">&times;</button>
            Configure Meter: {{meterDetail.mID}}
        </h4>
    </div>
    <div class="modal-body">
        <ng-container class="allutilitysloader col-12" *ngIf="loading">
            <fa name="spinner" animation="spin" size="4x"></fa>
        </ng-container>
        <div class="col-sm-12 row">
            <div class="col-sm-12 text-left">
                <div role="alert" *ngIf="message && message.length > 0">
                    <div class="alert alert-success " role="alert" *ngIf="thumb">
                        <mat-icon>thumb_up</mat-icon>&nbsp;{{message}}
                    </div>
                    <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                        <mat-icon>thumb_down</mat-icon>&nbsp;{{message}}
                    </div>
                </div>
            </div>
        </div>
        <ng-container>
            <div class="col-sm-12 col-sm-12 col-md-12 mx-auto form-wrapper">
                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Meter">
                    <legend class="scheduler-border">
                        General Configuration
                    </legend>
                    <div class="col-sm-12 row" *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config || 
                        getThrough.authority.Meters_Sub_Configure_Utility_Demand_Integration_Period_Config">
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config">
                            <form [formGroup]="actionForm1">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <mat-select placeholder="Select Capture Period"
                                                        name="capturedPeriod" formControlName="capturedPeriod"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted1 && aF1.capturedPeriod.errors }"
                                                        required>
                                                        <mat-option *ngFor="let td of capture" [value]="td.id">
                                                            {{td.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Load Profile Capture Period</label>
                                                    <div *ngIf="submitted1 && aF1.capturedPeriod.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted1 && aF1.capturedPeriod.errors.required">
                                                            Captured Period Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div class=iconconfigbtn>
                                                <div>
                                                    <button title="Get" class="dilog dilog1 buttonStyle"
                                                        [disabled]="isLoading" (click)="loadCapturePeriod('1')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config">
                                                        <i class="fa fa-download" style="font-size: 25px;"></i>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button title="Set" type="submit" [disabled]="isLoading"
                                                        class="dilog dilog1 buttonStyle" (click)="submitForm('1')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config">
                                                        <!-- <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoadingz1, 'fa-refresh': !isLoadingz1}" style="font-size: 15px;"></i>  -->
                                                        <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </form>
                        </div>

                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Demand_Integration_Period_Config">
                            <form [formGroup]="actionForm2">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <mat-select placeholder="Select Integration Period"
                                                        name="demandIntegrationPeriod"
                                                        formControlName="demandIntegrationPeriod" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && aF2.demandIntegrationPeriod.errors }"
                                                        required>
                                                        <mat-option *ngFor="let td of demand" [value]="td.id">
                                                            {{td.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Demand Integration Period</label>
                                                    <div *ngIf="submitted2 && aF2.demandIntegrationPeriod.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="submitted2 &&  aF2.demandIntegrationPeriod.errors.required">
                                                            Demand Integration Period Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div class=iconconfigbtn>
                                                <div>
                                                    <button title="Get" class="dilog dilog1 buttonStyle"
                                                        [disabled]="isLoading" (click)="loadCapturePeriod('2')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config">
                                                        <i class="fa fa-download" style="font-size: 25px;"></i>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button title="Set" type="submit" [disabled]="isLoading"
                                                        class="dilog dilog1 buttonStyle" (click)="submitForm('2')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config">
                                                        <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </form>
                        </div>


                    </div>
                    <div class="col-sm-12 row"
                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Push_Schedule_Config || getThrough.authority.Meters_Sub_Configure_Utility_Billing_Date_Config">
                        <!--<div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Config">
                            <form [formGroup]="actionForm3" (ngSubmit)="submitForm('3')">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Load Limit" name="loadLimit" formControlName="loadLimit"
                                                    class="form-control" [ngClass]="{ 'is-invalid': submitted3 && aF3.loadLimit.errors}"
                                                    required>
                                                    <i class="fa fa-user icon_innertxtbox"></i>
                                                    <label>Load Limit</label>
                                                    <div *ngIf="submitted3 && aF3.loadLimit.errors" class="invalid-feedback">
                                                        <div *ngIf="submitted3 && aF3.loadLimit.errors.required">
                                                            Load Limit Is required
                                                        </div>
                                                    </div>

                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <div class=iconconfigbtn>
                                        <div>
                                            <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoadingz1"
                                                (click)="loadCapturePeriod('3')"
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Read_Config">
                                                <i class="fa fa-download" style="font-size: 25px;"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <button title="Set" type="submit" [disabled]="isLoadingz1"
                                                class="dilog dilog1 buttonStyle" (click)="submitForm('3')"
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Write_Config">
                                                <i class="fa fa-upload" style="font-size: 25px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </table>
                            </form>
                        </div>-->
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Push_Schedule_Config">
                            <form [formGroup]="actionForm5">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <!--<input placeholder="Push Schedule" name="pushSchedular"
                                                    formControlName="pushSchedular" autocomplete="off"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted5 && aF5.pushSchedular.errors }"
                                                    required>
                                                <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                <label>Push Schedule</label>
                                                <div *ngIf="submitted5 && aF5.pushSchedular.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted5 &&  aF5.pushSchedular.errors.required">
                                                        Push Schedular Is requiredd
                                                    </div>
                                                </div>-->
                                                    <mat-select placeholder="Push Schedule" name="pushSchedular"
                                                        formControlName="pushSchedular" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted5 && aF5.pushSchedular.errors }"
                                                        required>
                                                        <mat-option *ngFor="let td of push" [value]="td.id">
                                                            {{td.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Push Schedule</label>
                                                    <div *ngIf="submitted5 && aF5.pushSchedular.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted5 && aF5.pushSchedular.errors.required">
                                                            Push Schedular Is requiredd
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <div class=iconconfigbtn>
                                        <div>
                                            <button title="Get" type="submit" [disabled]="isLoading"
                                                class="dilog dilog1 buttonStyle" (click)="loadCapturePeriod('5')"
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Push_Schedule_Read_Config">
                                                <i class="fa fa-download" style="font-size: 25px;"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <button title="Set" type="submit" [disabled]="isLoading"
                                                class="dilog dilog1 buttonStyle" (click)="submitForm('5')"
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Push_Schedule_Write_Config">
                                                <!--<mat-icon class="d-c4">update</mat-icon>-->
                                                <i class="fa fa-upload" style="font-size: 25px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </table>
                            </form>
                        </div>
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Billing_Date_Config">
                            <form [formGroup]="actionForm4">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Billing Date" name="billingDateFromMeter"
                                                        formControlName="billingDateFromMeter" autocomplete="off"
                                                        class="form-control">
                                                    <label>Get Billing Date</label>
                                                </span>
                                            </div>
                                        </td>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <!--<input placeholder="Billing Date" name="billingDate"
                                                        formControlName="billingDate" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted4 && aF4.billingDate.errors }"
                                                        required>-->
                                                    <input placeholder="Billing Date (YYYY-MM-DD)" name="billingDate"
                                                        formControlName="billingDate"
                                                        (bsValueChange)="getBillingDate($event)" bsDatepicker
                                                        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted4 && aD.billingDate.errors}"
                                                        required>

                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Set Billing Cycle(YYYY-MM-DD)</label>
                                                    <div *ngIf="submitted4 && aF4.billingDate.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted4 && aF4.billingDate.errors.required">
                                                            Billing Date Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                    <div class="iconconfigbtn">
                                        <div>
                                            <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoading"
                                                (click)="loadCapturePeriod('4')"
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Billing_Date_Read_Config">
                                                <i class="fa fa-download" style="font-size: 25px;"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <button title="Set" type="submit" [disabled]="isLoading"
                                                class="dilog dilog1 buttonStyle" (click)="submitForm('4')"
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Billing_Date_Write_Config">
                                                <i class="fa fa-upload" style="font-size: 25px;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </table>
                            </form>
                        </div>
                    </div>
                    <div class="col-sm-12 row">
                        <div class="col-sm-12 text-left">
                            <ng-container *ngIf="isLoadingz1">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                            </ng-container>
                            <div role="alert" *ngIf="message1 && message1.length > 0">
                                <div class="alert alert-success " role="alert" *ngIf="thumb">
                                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message1}}
                                </div>
                                <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message1}}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <form [formGroup]="actionForm26" (ngSubmit)="submitForm('26')">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Relay_Config">
                        <legend class="scheduler-border">
                            Recharge
                        </legend>
                        <div class="col-sm-12 row">
                            <div class="col-sm-4 text-left">
                                <mat-radio-group name="paymentMode" formControlName="paymentMode">
                                    <table>
                                        <tr>
                                            <td colspan="2" style="text-align-last: start;">
                                                <B>Payment Mode</B>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="background: white; text-align: -webkit-left;width:40%">
                                                <mat-radio-button value="1">Prepaid</mat-radio-button>
                                            </td>
                                            <td style="background: white; text-align: -webkit-left;width:40%">
                                                <mat-radio-button value="2">Postpaid</mat-radio-button>
                                            </td>
                                        </tr>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Last Token Amount" name="lastTokenAmount"
                                                        formControlName="lastTokenAmount" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.lastTokenAmount.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Last Token Amount</label>
                                                    <div *ngIf="submitted && aF1.lastTokenAmount.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.lastTokenAmount.errors.required">
                                                            Last Token Amount Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-sm-4 text-left">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Last Token Recharge Time"
                                                        name="lastTokenRechargeTime"
                                                        formControlName="lastTokenRechargeTime" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.lastTokenRechargeTime.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Last Token Recharge Time</label>
                                                    <div *ngIf="submitted && aF1.lastTokenRechargeTime.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.lastTokenRechargeTime.errors.required">
                                                            Last Token Recharge Time Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="col-sm-4 text-left">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Total Amount Last Recharge"
                                                        name="totalAmountLastRecharge"
                                                        formControlName="totalAmountLastRecharge" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.totalAmountLastRecharge.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Total Amount Last Recharge </label>
                                                    <div *ngIf="submitted && aF1.totalAmountLastRecharge.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.totalAmountLastRecharge.errors.required">
                                                            Total Amount Last Recharge Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-sm-4 text-left">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Current Balance Amount"
                                                        name="currentBalanceAmount"
                                                        formControlName="currentBalanceAmount" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.currentBalanceAmount.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Current Balance Amount</label>
                                                    <div *ngIf="submitted && aF1.currentBalanceAmount.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.currentBalanceAmount.errors.required">
                                                            Current Balance Amount Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-sm-4">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Current Balance Time" name="currentBalanceTime"
                                                        formControlName="currentBalanceTime" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.currentBalanceTime.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Current Balance Time</label>
                                                    <div *ngIf="submitted && aF1.currentBalanceTime.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.currentBalanceTime.errors.required">
                                                            Current Balance Time Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="col-sm-12">
                                <div style="float:right;">
                                    <button title="Set" type="submit" [disabled]="isLoading"
                                        class="dilog dilog1 buttonStyle" (click)="submitForm('26')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Relay_Write_Config">
                                        <i class="fa fa-upload" style="font-size: 25px;"></i>
                                    </button>
                                </div>
                                <div style="float:right;">
                                    <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoading"
                                        (click)="loadCapturePeriod('26')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Relay_Read_Config">
                                        <i class="fa fa-download" style="font-size: 25px;"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-sm-12 text-left">
                                <ng-container *ngIf="isLoadingz26">
                                    <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                                </ng-container>
                                <div role="alert" *ngIf="message26 && message26.length > 0">
                                    <div class="alert alert-success " role="alert" *ngIf="thumb">
                                        <mat-icon>thumb_up</mat-icon>&nbsp;{{message26}}
                                    </div>
                                    <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                        <mat-icon>thumb_down</mat-icon>&nbsp;{{message26}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>

                <form [formGroup]="actionForm6" (ngSubmit)="submitForm('6')">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Relay_Config">
                        <legend class="scheduler-border">
                            Relay Configuration
                        </legend>
                        <div class="col-sm-12 row">
                            <div class="col-sm-12 text-left">
                                <table style="margin-bottom:20px;">
                                    <tr class="mrflex">
                                        <td>
                                            <B>Relay Setting Status</B>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-sm-6 text-left">
                                <table>
                                    <tr>
                                        <td style="width:100% ;text-align-last: start;">
                                            <B>Auto Reconnection</B>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Auto Reconnection" name="autoReconnection"
                                                        formControlName="autoReconnection" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.autoReconnection.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Auto Reconnection</label>
                                                    <div *ngIf="submitted && aF1.autoReconnection.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.autoReconnection.errors.required">
                                                            Auto Reconnection Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-sm-6 text-left">
                                <table>
                                    <tr>
                                        <B>Time Interval (Reconnection) </B>
                                    </tr>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Time Interval" name="timeIntervaL"
                                                        formControlName="timeIntervaL" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.timeIntervaL.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Time Interval (Reconnection) </label>
                                                    <div *ngIf="submitted && aF1.timeIntervaL.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.timeIntervaL.errors.required">
                                                            Time Interval Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-sm-6 text-left">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="lockout Period" name="lockOutPeriod"
                                                        formControlName="lockOutPeriod" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.lockOutPeriod.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Lockout Period (Relay) </label>
                                                    <div *ngIf="submitted && aF1.lockOutPeriod.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.lockOutPeriod.errors.required">
                                                            Loackout Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-sm-6">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="Activation Time" name="activationTime"
                                                        formControlName="activationTime" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF1.activationTime.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Activation Time (Relay)</label>
                                                    <div *ngIf="submitted && aF1.activationTime.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF1.activationTime.errors.required">
                                                            Activation Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <div style="float:right;">
                                    <button title="Set" type="submit" [disabled]="isLoading"
                                        class="dilog dilog1 buttonStyle" (click)="submitForm('6')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Relay_Write_Config">
                                        <i class="fa fa-upload" style="font-size: 25px;"></i>
                                    </button>
                                </div>
                                <div style="float:right;">
                                    <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoading"
                                        (click)="loadCapturePeriod('6')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Relay_Read_Config">
                                        <i class="fa fa-download" style="font-size: 25px;"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>

                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config">
                    <legend class="scheduler-border">
                        General Configuration
                    </legend>
                    <div class="col-sm-12 row">
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Prepaid_Config && loadLimitStatus">
                            <form [formGroup]="actionForm19">
                                <mat-radio-group name="prepaidFunctionNew" formControlName="prepaidFunctionNew">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="2" style="text-align-last: start;">
                                                    <B>Payment Mode</B>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;width:40%">
                                                    <mat-radio-button value="1">Prepaid</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;width:40%">
                                                    <mat-radio-button value="2">Postpaid</mat-radio-button>
                                                </td>


                                                <td style="width:10%">
                                                    <div style="float:right;">
                                                        <button title="Get" class="dilog dilog1 buttonStyle"
                                                            [disabled]="isLoading" (click)="loadCapturePeriod('7')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config">
                                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td style="width:10%">
                                                    <div style="float:right;">
                                                        <button title="Set" type="submit" [disabled]="isLoading"
                                                            class="dilog dilog1 buttonStyle" (click)="submitForm('7')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config">
                                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </form>
                        </div>
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config">
                            <form [formGroup]="actionForm8" (ngSubmit)="submitForm('8')">
                                <mat-radio-group name="netMeters" formControlName="netMeters">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="2" style="text-align-last: start;">
                                                    <B>Metering Mode</B>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left; width:40%">
                                                    <mat-radio-button value="1">Import/Export</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left; width:40%">
                                                    <mat-radio-button value="2">Import Only</mat-radio-button>
                                                </td>
                                                <td style="width:10%">
                                                    <div style="float:right;">
                                                        <button title="Get" class="dilog dilog1 buttonStyle"
                                                            [disabled]="isLoading" (click)="loadCapturePeriod('8')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config">
                                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td style="width:10%">
                                                    <div style="float:right;">
                                                        <button title="Set" type="submit" [disabled]="isLoading"
                                                            class="dilog dilog1 buttonStyle" (click)="submitForm('8')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config">
                                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </form>
                        </div>
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config && loadLimitStatus">
                            <form [formGroup]="actionForm20">
                                <!--<mat-radio-group name="connectDisconnectControl"
                                    formControlName="connectDisconnectControl">-->
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="2" style="text-align-last: start;">
                                                    <B>Load Control</B>
                                                </td>
                                            </tr>
                                            <tr>
                                                <!--<td style="background: white; text-align: -webkit-left;width:40%">
                                                    <mat-radio-button value="0">Disable</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;width:40%">
                                                    <mat-radio-button value="4">Manual</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;width:40%">
                                                    <mat-radio-button value="6">Auto</mat-radio-button>
                                                </td>-->
                                                <mat-select placeholder="Select Control Mode"
                                                    name="connectDisconnectControl"
                                                    formControlName="connectDisconnectControl" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted2 && aF2.connectDisconnectControl.errors }"
                                                    required>
                                                    <mat-option *ngFor="let td of mode" [value]="td.id">
                                                        {{td.Name}}
                                                    </mat-option>
                                                </mat-select>
                                               
                                                
                                                <div *ngIf="submitted2 && aF2.connectDisconnectControl.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="submitted2 &&  aF2.connectDisconnectControl.errors.required">
                                                        Control Mode Is required
                                                    </div>
                                                </div>
                                            <td style="width:10%">
                                                <div style="float:right;">
                                                    <button title="Get" class="dilog dilog1 buttonStyle"
                                                        [disabled]="isLoading" (click)="loadCapturePeriod('20')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config">
                                                        <i class="fa fa-download" style="font-size: 25px;"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td style="width:10%">
                                                <div style="float:right;">
                                                    <button title="Set" type="submit" [disabled]="isLoading"
                                                        class="dilog dilog1 buttonStyle" (click)="submitForm('20')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config">
                                                        <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            </tr>
                                        </div>
                                    </table>
                                <!--</mat-radio-group>-->
                            </form>
                        </div>
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config && loadLimitStatus">
                            <form [formGroup]="actionForm9" (ngSubmit)="submitForm('9')">
                                <mat-radio-group name="connectDisconnect" formControlName="connectDisconnect">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="2" style="text-align-last: start;">
                                                    <B>Connect/Disconnect</B>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left; width:40%">
                                                    <mat-radio-button value="1">Connect</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left; width:40%">
                                                    <mat-radio-button value="2">Disconnect</mat-radio-button>
                                                </td>
                                                <td style="width:10%">
                                                    <div style="float:right;">
                                                        <button title="Get" class="dilog dilog1 buttonStyle"
                                                            [disabled]="isLoading" (click)="loadCapturePeriod('9')">
                                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td style="width:10%">
                                                    <div style="float:right;">
                                                        <button title="Set" type="submit" [disabled]="isLoading"
                                                            class="dilog dilog1 buttonStyle" (click)="submitForm('9')">
                                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </form>
                        </div>
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config">
                            <form [formGroup]="actionForm10" (ngSubmit)="submitForm('10')">
                                <mat-radio-group name="mdReset" formControlName="mdReset">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td style="text-align-last: start;">
                                                    <B>MD Reset</B>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white;width: 90%;text-align: -webkit-left;">
                                                    <mat-radio-button value="1">Reset</mat-radio-button>
                                                </td>
                                                <td>
                                                    <div class="iconconfigbtn">
                                                        <!--<button [disabled]="isLoading" style="border: 0;background: azure;"
                                                        (click)="loadCapturePeriod('10')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config">
                                                        <i class="fa d-c4"
                                                            [ngClass]="{'fa-spin fa-spinner': isLoading10, 'fa-refresh': !isLoading10}"
                                                            style="font-size: 20px;"></i>
                                                    </button>-->
                                                        <div style="float:right;">
                                                            <button title="Set" class="btnref" type="submit"
                                                                [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                                                (click)="submitForm('10')"
                                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config">
                                                                <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>

                                </mat-radio-group>
                            </form>
                        </div>
                        <div class="col-sm-6 text-left"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config">
                            <form [formGroup]="actionForm24" (ngSubmit)="submitForm('24')">
                                <mat-radio-group name="ping" formControlName="ping">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td style="text-align-last: start;">
                                                    <B>Ping</B>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white;width: 90%;text-align: -webkit-left;">
                                                    <mat-radio-button value="1">Ping</mat-radio-button>
                                                </td>
                                                <div class="iconconfigbtn">
                                                    <!--<button [disabled]="isLoading" style="border: 0;background: azure;"
                                                        (click)="loadCapturePeriod('10')"
                                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config">
                                                        <i class="fa d-c4"
                                                            [ngClass]="{'fa-spin fa-spinner': isLoading10, 'fa-refresh': !isLoading10}"
                                                            style="font-size: 20px;"></i>
                                                    </button>-->
                                                    <div style="float:right;">
                                                        <button title="Set" class="btnref" type="submit"
                                                            [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                                            (click)="submitForm('24')"
                                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config">
                                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </tr>
                                        </div>
                                    </table>

                                </mat-radio-group>
                            </form>
                        </div>

                    </div>
                    <div class="col-sm-12 row">
                        <div class="col-sm-12 text-left">
                            <ng-container *ngIf="isLoading">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                            </ng-container>
                            <div role="alert" *ngIf="message2 && message2.length > 0">
                                <div class="alert alert-success " role="alert" *ngIf="thumb">
                                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message2}}
                                </div>
                                <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message2}}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <form [formGroup]="actionForm22" (ngSubmit)="submitFota()">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Config_Firmware_Version">
                   
                        <legend class="scheduler-border">
                            Firmware Version
                        </legend>
                        <div class="row">
                            <table>
                                <tr>
                                    <td style="width:92%;text-align-last: start;">
                                        <B>Firmware Version Upgrade</B>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Config Date (MM/DD/YYYY)" name="firmWareVersionDate"
                                            autocomplete="off" formControlName="firmWareVersionDate"
                                            (bsValueChange)="getDateFromRange($event)" bsDatepicker
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF22.firmWareVersionDate.errors }"
                                            required>
                                        <i class="fa fa-calendar icon_innertxtbox"></i>
                                        <label>Activation Date</label>
                                        <div *ngIf="aF22.firmWareVersionDate.invalid && (aF22.firmWareVersionDate.dirty || aF22.firmWareVersionDate.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="aF22.firmWareVersionDate.errors.required">
                                                Activation required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <!--<div class="col-md-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Old Activation Date (DD/MM/YYYY)" name="pastFirmWareDate"
                                            autocomplete="off" formControlName="pastFirmWareDate" bsDatepicker
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF22.pastFirmWareDate.errors }"
                                            required>
                                        <i class="fa fa-calendar icon_innertxtbox"></i>
                                        <label>Old Firmware Upgradation Date (DD/MM/YYYY)</label>

                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Old Firmware Name" name="oldFirmWare" autocomplete="off"
                                            formControlName="oldFirmWare" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF22.oldFirmWare.errors }" required>
                                        <i class="fa fa-calendar icon_innertxtbox"></i>
                                        <label>Old Firmware Version</label>

                                    </span>
                                </div>
                            </div>-->
                            <div class="col-sm-4">
                                <span class="has-float-label">
                                    <input type="text" #autocompleteInput placeholder="Firmware Version"
                                        [matAutocomplete]="auto" [formControl]="flatControl"
                                        class="form-control col-md-12" style="padding-left: 40px">
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFlatInfo">
                                        <mat-option *ngFor="let option of filteredFlatInfo | async" [value]="option"
                                            (onSelectionChange)="onSelectFlat($event, option.id)">
                                            {{option.Name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <i class="fa fa-edit icon_innertxtbox"></i>
                                    <label>Select Firmware Version</label>
                                    <div *ngIf="submitted && flatControl.errors" class="invalid-feedback">
                                        <div *ngIf="flatControl.errors.required">
                                            Firmware version Is required
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <!--<div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="file" accept=".bin" placeholder="Select Sec-1 File" name="sec1File"
                                            formControlName="sec1File" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF22.sec1File.errors }" required
                                            (change)="onFileSelectSec1($event)">
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <label> Select Sec-1 File </label>
                                        <div *ngIf="submitted && aF22.sec1File.errors" class="invalid-feedback">
                                            <div *ngIf="aF22.sec1File.errors.required">
                                                Sec-1 File Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="file" accept=".bin" placeholder="Select Sec-2 File" name="sec2File"
                                            formControlName="sec2File" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF22.sec2File.errors }" required
                                            (change)="onFileSelectSec2($event)">
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <label> Select Sec-2 File </label>
                                        <div *ngIf="submitted && aF22.sec2File.errors" class="invalid-feedback">
                                            <div *ngIf="aF22.sec2File.errors.required">
                                                Sec-2 File Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>-->
                        </div>

                        <div class="row">
                            <div class="col-sm-8 text-left">
                                <div role="alert" *ngIf="message22 && message22.length > 0">
                                    <div class="alert alert-success " role="alert" *ngIf="thumb">
                                        <mat-icon>thumb_up</mat-icon>&nbsp;{{message22}}
                                    </div>
                                    <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                        <mat-icon>thumb_down</mat-icon>&nbsp;{{message22}}
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 text-right">
                                <button type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                    style="margin-left: -40px;">
                                    <i class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoadingz22, 'fa-refresh': !isLoadingz22}"
                                        style="font-size: 15px;"></i>
                                    {{button22}}
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <form [formGroup]="actionForm11">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Time_Config">
                        <legend class="scheduler-border">
                            Time Configuration
                        </legend>
                        <div class="col-sm-12 row">
                            <table>
                                <tr>
                                    <td style="width:92%;text-align-last: start;">
                                        <B>Time Synchronization</B>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-sm-2 text-left">
                                Meter D/T
                            </div>
                            <div class="col-sm-3 text-left">
                                {{meterDateTime}}
                            </div>
                            <div class="col-sm-2 text-left">
                                Network D/T
                            </div>
                            <div class="col-sm-3 text-left">
                                {{serverDateTime}}
                            </div>
                        </div>
                        <div class="iconconfigbtn">
                            <!--<button [disabled]="isLoading" style="border: 0;background: azure;"
                                (click)="loadCapturePeriod('11')"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Time_Read_Config">
                                <i class="fa d-c4"
                                    [ngClass]="{'fa-spin fa-spinner': isLoading11, 'fa-refresh': !isLoading11}"
                                    style="font-size: 20px;"></i>
                            </button>-->
                            <div style="float:right;">
                                <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoading"
                                    (click)="loadCapturePeriod('11')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Time_Read_Config">
                                    <i class="fa fa-download" style="font-size: 25px;"></i>
                                </button>
                            </div>
                            <div style="float:right;">
                                <button title="Set" class="btnref" type="submit" [disabled]="isLoading"
                                    class="dilog dilog1 buttonStyle" (click)="submitForm('11')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Time_Write_Config">
                                    <i class="fa fa-upload" style="font-size: 25px;"></i>
                                </button>
                            </div>
                            <!--<button class="btnref" type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                (click)="submitForm('11')"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Time_Write_Config">
                                <i class="fa"
                                    [ngClass]="{'fa-spin fa-spinner': isLoadingz11, 'fa-refresh': !isLoadingz11}"
                                    style="font-size: 15px;"></i>
                                {{button11}}
                            </button>-->

                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-sm-3 text-left">
                                Datetime Diffrence
                            </div>
                            <div class="col-sm-7 text-left">
                                {{timeDiffrence}}
                            </div>
                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-sm-12 text-left">
                                <ng-container *ngIf="isLoadingz11">
                                    <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                                </ng-container>
                                <div role="alert" *ngIf="message11 && message11.length > 0">
                                    <div class="alert alert-success " role="alert" *ngIf="thumb">
                                        <mat-icon>thumb_up</mat-icon>&nbsp;{{message11}}
                                    </div>
                                    <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                        <mat-icon>thumb_down</mat-icon>&nbsp;{{message11}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>

                <fieldset class="scheduler-border"
                    *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Password_Config">
                    <legend class="scheduler-border">
                        Password Configuration
                    </legend>
                    <div class="row">
                        <B>Password</B>
                    </div>
                    <div class=" row"
                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config || getThrough.authority.Meters_Sub_Configure_Utility_Password_High_Level_Write_Config">
                        <div class="col-sm-6 text-left">
                            <form [formGroup]="actionForm12" (ngSubmit)="submitForm('16')">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input matInput placeholder="Low level" name="lowLevel"
                                                        formControlName="lowLevel" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF12.lowLevel.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label>Low level</label>
                                                    <div *ngIf="submitted && aF12.lowLevel.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF12.lowLevel.errors.required">
                                                            Low Level Password Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="iconconfigbtn">
                                            <!--<button type="submit" [disabled]="isLoading"
                                                class="dilog dilog1 buttonStyle" (click)="submitForm('16')"
                                                style="margin-left: -40px;"
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config">
                                                <i class="fa"
                                                    [ngClass]="{'fa-spin fa-spinner': isLoadingz16, 'fa-refresh': !isLoadingz16}"
                                                    style="font-size: 15px;"></i>
                                                {{button16}}
                                            </button>-->
                                            <div style="float:right;">
                                                <button title="Set" class="btnref" type="submit" [disabled]="isLoading"
                                                    class="dilog dilog1 buttonStyle" (click)="submitForm('16')"
                                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config">
                                                    <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </form>
                        </div>
                        <div class="col-sm-6 text-left">
                            <form [formGroup]="actionForm18" (ngSubmit)="submitForm('18')">
                                <table>
                                    <tr>
                                        <td style="background: white;">
                                            <div class="form-group input-group">
                                                <span class="has-float-label">
                                                    <input placeholder="High Level" name="highLevel"
                                                        formControlName="highLevel" autocomplete="off"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && aF18.highLevel.errors }"
                                                        required>
                                                    <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                                    <label> High Level</label>
                                                    <div *ngIf="submitted && aF18.highLevel.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="aF18.highLevel.errors.required">
                                                            High Level Password Is required
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="iconconfigbtn">
                                            <!--<button type="submit" [disabled]="isLoading"
                                                class="dilog dilog1 buttonStyle" (click)="submitForm('18')"
                                                style="margin-left: -40px;"
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Password_High_Level_Write_Config">
                                                <i class="fa"
                                                    [ngClass]="{'fa-spin fa-spinner': isLoadingz18, 'fa-refresh': !isLoadingz18}"
                                                    style="font-size: 15px;"></i>
                                                {{button18}}
                                            </button>-->
                                            <div style="float:right;">
                                                <button title="Set" class="btnref" type="submit" [disabled]="isLoading"
                                                    class="dilog dilog1 buttonStyle" (click)="submitForm('18')"
                                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Password_High_Level_Write_Config">
                                                    <i class="fa fa-upload" style="font-size: 25px;"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </form>
                        </div>
                    </div>

                    <div class="row" *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Enc_Auth_Key_Config">
                        <B>Encription & Auth key</B>
                    </div>
                    <form [formGroup]="actionForm13" (ngSubmit)="submitForm('17')">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Master Key" name="masterKey" formControlName="masterKey"
                                            autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF13.masterKey.errors }" required>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <label>Master Key</label>
                                        <div *ngIf="submitted && aF13.masterKey.errors" class="invalid-feedback">
                                            <div *ngIf="aF13.masterKey.errors.required">
                                                Master key Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6 text-left">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="New Key" name="newKey" formControlName="newKey"
                                            autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF13.masterKey.errors }" required>
                                        <label>New Key</label>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aF13.newKey.errors" class="invalid-feedback">
                                            <div *ngIf="aF13.newKey.errors.required">
                                                New key Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="iconconfigbtn">
                            <!--<button type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                (click)="submitForm('17')"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config">
                                <i class="fa"
                                    [ngClass]="{'fa-spin fa-spinner': isLoadingz17, 'fa-refresh': !isLoadingz17}"
                                    style="font-size: 15px;"></i>
                                {{button17}}
                            </button>-->
                            <div style="float:right;">
                                <button title="Set" class="btnref" type="submit" [disabled]="isLoading"
                                    class="dilog dilog1 buttonStyle" (click)="submitForm('17')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config">
                                    <i class="fa fa-upload" style="font-size: 25px;"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </fieldset>

                <form [formGroup]="actionForm14" (ngSubmit)="submitForm('12')">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_ThrashHold_Limit_Config">
                        <legend class="scheduler-border">
                            Threshold Limit Configuration
                        </legend>

                        <div class="row">
                            <table>
                                <tr>
                                    <td style="width:92%;text-align-last: start;">
                                        <B>Threshold Limits</B>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Over Voltage" name="overVoltage"
                                            formControlName="overVoltage" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF14.overVoltage.errors }" required>
                                        <label> Over Voltage</label>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aF14.overVoltage.errors" class="invalid-feedback">
                                            <div *ngIf="aF14.overVoltage.errors.required">
                                                Over Voltage Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Low Voltage" name="lowVoltage" formControlName="lowVoltage"
                                            autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF14.lowVoltage.errors }" required>
                                        <label> Low Voltage</label>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aF14.lowVoltage.errors" class="invalid-feedback">
                                            <div *ngIf="aF14.lowVoltage.errors.required">
                                                Low Voltage Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Over Current" name="overCurrent"
                                            formControlName="overCurrent" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF14.overCurrent.errors }" required>
                                        <label> Over Current</label>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aF14.overCurrent.errors" class="invalid-feedback">
                                            <div *ngIf="aF14.overCurrent.errors.required">
                                                Over Current Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input placeholder="Activation datetime" name="activationDatetime"
                                            formControlName="activationDatetime" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF14.activationDatetime.errors }"
                                            required>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <div *ngIf="submitted && aF14.activationDatetime.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="aF14.activationDatetime.errors.required">
                                                Activation Datetime Is required
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="iconconfigbtn">
                            <!--<button class="btnref" [disabled]="isLoading" style="border: 0;background: azure;"
                                (click)="loadCapturePeriod('12')"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config">
                                <i class="fa d-c4"
                                    [ngClass]="{'fa-spin fa-spinner': isLoading12, 'fa-refresh': !isLoading12}"
                                    style="font-size: 20px;"></i>
                            </button>-->
                            <div style="float:right;">
                                <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoading"
                                    (click)="loadCapturePeriod('12')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config">
                                    <i class="fa fa-download" style="font-size: 25px;"></i>
                                </button>
                            </div>
                            <!--<button type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                (click)="submitForm('12')"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config">
                                <i class="fa"
                                    [ngClass]="{'fa-spin fa-spinner': isLoadingz12, 'fa-refresh': !isLoadingz12}"
                                    style="font-size: 15px;"></i>
                                {{button12}}
                            </button>-->
                            <div style="float:right;">
                                <button title="Set" class="btnref" type="submit" [disabled]="isLoading"
                                    class="dilog dilog1 buttonStyle" (click)="submitForm('12')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config">
                                    <i class="fa fa-upload" style="font-size: 25px;"></i>
                                </button>
                            </div>

                        </div>
                    </fieldset>
                </form>

                <form [formGroup]="actionForm21" *ngIf="loadLimitStatus">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_Load_Limiter_Config">
                        <legend class="scheduler-border">
                            Load Limiter Configuration
                        </legend>
                        <div class="row">
                            <table>
                                <tr>
                                    <td style="width:92%;text-align-last: start;">
                                        <B>Load Limiter</B>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="number" placeholder="Load Limit" name="loadLimit"
                                            formControlName="loadLimit" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF1.loadLimit.errors }" required>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <label> Load Limit <b>(W)</b> </label>
                                        <div *ngIf="submitted && aF1.loadLimit.errors" class="invalid-feedback">
                                            <div *ngIf="aF1.loadLimit.errors.required">
                                                Load Limit Is required
                                            </div>
                                            <div *ngIf="aF21.loadLimit.hasError('min')">
                                                Load Limit is lower than the 0.1 W
                                            </div>
                                            <div *ngIf="aF21.loadLimit.hasError('max')">
                                                Load Limit is greater than the 50000 W
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="number" placeholder="Disconnection Time" name="lockOutPeriod"
                                            formControlName="lockOutPeriod" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF21.lockOutPeriod.errors }"
                                            required>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <label> Disconnection Time <b>(Seconds)</b></label>
                                        <div *ngIf="submitted && aF21.lockOutPeriod.errors" class="invalid-feedback">
                                            <div *ngIf="aF21.lockOutPeriod.errors.required">
                                                Lockout period Is required
                                            </div>
                                            <div *ngIf="aF21.lockOutPeriod.hasError('min')">
                                                Lockout period is lower than the 5 Seconds
                                            </div>
                                            <div *ngIf="aF21.lockOutPeriod.hasError('max')">
                                                Lockout period is greater than the 30 Seconds
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group input-group">
                                    <span class="has-float-label">
                                        <input type="number" placeholder="Relay Connect" name="relayConnDis"
                                            formControlName="relayConnDis" autocomplete="off" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && aF21.relayConnDis.errors }"
                                            required>
                                        <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                        <label> Relay Connect Time <b>(Seconds)</b></label>
                                        <div *ngIf="submitted && aF21.relayConnDis.errors" class="invalid-feedback">
                                            <div *ngIf="aF21.relayConnDis.errors.required">
                                                Relay Connect Interval Time required
                                            </div>
                                            <div *ngIf="aF21.relayConnDis.hasError('min')">
                                                Relay Connect Interval is lower than 1 Seconds
                                            </div>
                                            <div *ngIf="aF21.relayConnDis.hasError('max')">
                                                Relay Connect Interval greater than 30 Seconds
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="iconconfigbtn">
                                    <!--<button class="btnref" [disabled]="isLoading" style="border: 0;background: azure;"
                                        (click)="loadCapturePeriod('21')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Load_Limiter_Read_Config">
                                        <i class="fa d-c4"
                                            [ngClass]="{'fa-spin fa-spinner': isLoading21, 'fa-refresh': !isLoading21}"
                                            style="font-size: 20px;"></i>
                                    </button>-->

                                    <div style="float:right;">
                                        <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoading"
                                            (click)="loadCapturePeriod('21')"
                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Load_Limiter_Read_Config">
                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                        </button>
                                    </div>
                                    <!--<button type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                        (click)="submitForm('21')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Load_Limiter_Write_Config">
                                        <i class="fa"
                                            [ngClass]="{'fa-spin fa-spinner': isLoadingz21, 'fa-refresh': !isLoadingz21}"
                                            style="font-size: 15px;"></i>
                                        {{button21}}
                                    </button>-->
                                    <div style="float:right;">
                                        <button title="Set" class="btnref" type="submit" [disabled]="isLoading"
                                            class="dilog dilog1 buttonStyle" (click)="submitForm('21')"
                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_Load_Limiter_Write_Config">
                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 text-left">
                                <ng-container *ngIf="isLoadingz21">
                                    <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                                </ng-container>
                                <div role="alert" *ngIf="message21 && message21.length > 0">
                                    <div class="alert alert-success " role="alert" *ngIf="thumb">
                                        <mat-icon>thumb_up</mat-icon>&nbsp;{{message21}}
                                    </div>
                                    <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                        <mat-icon>thumb_down</mat-icon>&nbsp;{{message21}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <form [formGroup]="actionForm15" (ngSubmit)="submitForm('13')">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Config">
                        <legend class="scheduler-border">
                            TOD Zone Setting
                        </legend>
                        <div class="container">
                            <div class="row">
                                <table>
                                    <tr>
                                        <td style="width:92%;text-align-last: start;">
                                            <B>TOD Configuration</B>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input placeholder="Activation Date (MM/DD/YYYY)"
                                                name="schedularStartEndDate" autocomplete="off"
                                                formControlName="schedularStartEndDate"
                                                (bsValueChange)="getDateFromRange($event)" bsDatepicker
                                                [bsConfig]="bsConfig" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted15 && aF15.schedularStartEndDate.errors }"
                                                required>
                                            <i class="fa fa-calendar icon_innertxtbox"></i>
                                            <label>Activation Date</label>
                                            <div *ngIf="aF15.schedularStartEndDate.invalid && (aF15.schedularStartEndDate.dirty || aF15.schedularStartEndDate.touched)"
                                                class="alert alert-danger">
                                                <div *ngIf="aF15.schedularStartEndDate.errors.required">
                                                    Activation Date required
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input placeholder="Start Date (DD/MM/YYYY)" name="pastTeriffDate"
                                                autocomplete="off" formControlName="pastTeriffDate" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted15 && aF15.pastTeriffDate.errors }"
                                                required>
                                            <i class="fa fa-calendar icon_innertxtbox"></i>
                                            <label>Old Tod Config Date (MM/DD/YYYY)</label>

                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3 "
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_1_Config">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Active TOD Zone 1" name="atod1"
                                                formControlName="atod1" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_1_Config">
                                                Active TOD Zone 1</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3 "
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_1_Config">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Passive TOD Zone 1" name="tod1"
                                                formControlName="tod1" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_1_Config">
                                                Passive TOD Zone 1</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_2_Config">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Active TOD Zone 2" name="atod2"
                                                formControlName="atod2" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_2_Config">
                                                Active TOD Zone 2</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                           
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_2_Config">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Passive TOD Zone 2" name="tod2"
                                                formControlName="tod2" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_2_Config">
                                                Passive TOD Zone 2</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                           
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_3_Config">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Active TOD Zone 3" name="atod3"
                                                formControlName="atod3" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_3_Config">
                                                Active TOD Zone 3</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_3_Config">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Passive TOD Zone 3" name="tod3"
                                                formControlName="tod3" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_3_Config">
                                                Passive TOD Zone 3</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_4_Config">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Active TOD Zone 4" name="atod4"
                                                formControlName="atod4" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_4_Config">
                                                Active TOD Zone 4</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                           
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_4_Config">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Passive TOD Zone 4" name="tod4"
                                                formControlName="tod4" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_4_Config">
                                                Passive TOD Zone 4</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                           
                                        </span>
                                    </div>
                                </div>

                                <div class="col-sm-3 "
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_5_Config  && todZoneStatus">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Active TOD Zone 5" name="atod5"
                                                formControlName="atod5" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_5_Config">
                                                Active TOD Zone 5</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3 "
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_5_Config  && todZoneStatus">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Passive TOD Zone 5" name="tod5"
                                                formControlName="tod5" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_5_Config">
                                                Passive TOD Zone 5</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_6_Config  && todZoneStatus">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Active TOD Zone 6" name="atod6"
                                                formControlName="atod6" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_6_Config">
                                                Active TOD Zone 6</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                           
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_6_Config  && todZoneStatus">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Passive TOD Zone 6" name="tod6"
                                                formControlName="tod6" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_6_Config">
                                                Passive TOD Zone 6</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                           
                                        </span>
                                    </div>
                                </div>

                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_7_Config  && todZoneStatus">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Active TOD Zone 7" name="atod7"
                                                formControlName="atod7" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_7_Config">
                                                Active TOD Zone 7</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                           
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_7_Config  && todZoneStatus">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Passive TOD Zone 7" name="tod7"
                                                formControlName="tod7" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_7_Config">
                                                Passive TOD Zone 7</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                           
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_8_Config  && todZoneStatus">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Active TOD Zone 8" name="atod8"
                                                formControlName="atod8" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_8_Config">
                                                Active TOD Zone 8</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>

                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-3"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_8_Config  && todZoneStatus">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Passive TOD Zone 8" name="tod8"
                                                formControlName="tod8" autocomplete="off" class="form-control">
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_8_Config">
                                                Passive TOD Zone 8</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row iconconfigbtn">
                                <!--<button class="btnref" [disabled]="isLoading" style="border: 0;background: azure;"
                                    (click)="loadCapturePeriod('13')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Read_Config">
                                    <i class="fa d-c4"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading13, 'fa-refresh': !isLoading13}"
                                        style="font-size: 20px;"></i>
                                </button>-->
                                <div style="float:right;">
                                    <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoadingz13"
                                        (click)="loadCapturePeriod('13')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Read_Config">
                                        <i class="fa fa-download" style="font-size: 25px;"></i>
                                    </button>
                                </div>
                                <!--<div class="">
                                    <button type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                        (click)="submitForm('13')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Write_Config">
                                        <i class="fa"
                                            [ngClass]="{'fa-spin fa-spinner': isLoadingz13, 'fa-refresh': !isLoadingz13}"
                                            style="font-size: 15px;"></i>
                                        {{button13}}
                                    </button>
                                </div>-->
                                <div style="float:right;">
                                    <button title="Set" class="btnref" type="submit" [disabled]="isLoadingz13"
                                        class="dilog dilog1 buttonStyle" (click)="submitForm('13')"
                                        *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Write_Config">
                                        <i class="fa fa-upload" style="font-size: 25px;"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-12 row">
                                <div class="col-sm-12 text-left">
                                    <ng-container *ngIf="isLoadingz13">
                                        <fa-icon [icon]="faSpinner" [spin]="true" size="3x"
                                            class="makeCenter"></fa-icon>
                                    </ng-container>
                                    <div role="alert" *ngIf="message13 && message13.length > 0">
                                        <div class="alert alert-success " role="alert" *ngIf="thumb">
                                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message13}}
                                        </div>
                                        <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message13}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <form [formGroup]="actionForm23" (ngSubmit)="submitForm('23')">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.DCU_Sub_Configure_Happy_Hours_On_Meter">
                        <legend class="scheduler-border">
                            Happy Hours Setting
                        </legend>
                        <div class="container">
                            <div class="row">
                                <table>
                                    <tr>
                                        <td style="width:92%;text-align-last: start;">
                                            <B>Happy Hours</B>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input placeholder="Activation Date (MM/DD/YYYY)"
                                                name="schedularStartEndDateHappy" autocomplete="off"
                                                formControlName="schedularStartEndDateHappy"
                                                (bsValueChange)="getDateFromRange($event)" bsDatepicker
                                                [bsConfig]="bsConfig" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF23.schedularStartEndDateHappy.errors }"
                                                required>
                                            <i class="fa fa-calendar icon_innertxtbox"></i>
                                            <label>Activation Date</label>
                                            <div *ngIf="aF23.schedularStartEndDateHappy.invalid && (aF23.schedularStartEndDateHappy.dirty || aF23.schedularStartEndDateHappy.touched)"
                                                class="alert alert-danger">
                                                <div *ngIf="aF23.schedularStartEndDateHappy.errors.required">
                                                    Activation Date required
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input placeholder="Start Date (DD/MM/YYYY)" name="pastHappyDate"
                                                autocomplete="off" formControlName="pastHappyDate" bsDatepicker
                                                [bsConfig]="bsConfig" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF23.pastHappyDate.errors }"
                                                required>
                                            <i class="fa fa-calendar icon_innertxtbox"></i>
                                            <label>Old Happy Hours Config Date (DD/MM/YYYY)</label>

                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-6 ">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Happy Hour Start" name="startTime"
                                                formControlName="startTime" autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF23.startTime.errors }"
                                                required>
                                            <label>
                                                Happy Hour Start (HH:MM)</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF23.startTime.errors" class="invalid-feedback">
                                                <div *ngIf="aF23.startTime.errors.required">
                                                    Happy Hour Start Is required
                                                </div>
                                            </div>
                                            <!--<input placeholder="TOD Zone 1" name="tod1" formControlName="tod1"
                                                autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF15.tod1.errors }" required>
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_1_Config">
                                                TOD Zone 1 <b>(Minute)</b></label>
                                            <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF15.tod1.errors" class="invalid-feedback">
                                                <div *ngIf="aF15.tod1.errors.required">
                                                    Tod Zone 1 Is required
                                                </div>
                                            </div>-->
                                        </span>

                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="time" matInput placeholder="Happy Hours End" name="endTime"
                                                formControlName="endTime" autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF23.endTime.errors }" required>
                                            <label>
                                                Happy Hour End (HH:MM)</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF23.endTime.errors" class="invalid-feedback">
                                                <div *ngIf="aF23.endTime.errors.required">
                                                    Happy Hour End Is required
                                                </div>
                                            </div>
                                            <!--<input placeholder="TOD Zone 2" name="tod2" formControlName="tod2"
                                                autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF15.tod2.errors }" required>
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_2_Config">
                                                TOD Zone 2</label>
                                            <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF15.tod2.errors" class="invalid-feedback">
                                                <div *ngIf="aF15.tod2.errors.required">
                                                    Tod Zone 2 Is required
                                                </div>
                                            </div>-->
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="text" matInput placeholder="Holiday Months" name="holidayMonth"
                                                formControlName="holidayMonth" autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF23.holidayMonth.errors }"
                                                required>
                                            <label>
                                                Holiday Months</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF23.holidayMonth.errors" class="invalid-feedback">
                                                <div *ngIf="aF23.holidayMonth.errors.required">
                                                    Holiday Months are required
                                                </div>
                                            </div>
                                            <!--<input placeholder="TOD Zone 3" name="tod3" formControlName="tod3"
                                                autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF15.tod2.errors }" required>
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_3_Config">
                                                TOD Zone 3</label>
                                            <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF15.tod3.errors" class="invalid-feedback">
                                                <div *ngIf="aF15.tod3.errors.required">
                                                    Tod Zone 3 Is required
                                                </div>
                                            </div>-->
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="text" matInput placeholder="Holiday Dates" name="holidayDate"
                                                formControlName="holidayDate" autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF23.holidayDate.errors }"
                                                required>
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_4_Config">
                                                Holiday Dates</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF23.holidayDate.errors" class="invalid-feedback">
                                                <div *ngIf="aF23.holidayDate.errors.required">
                                                    Holiday Dates are required
                                                </div>
                                            </div>
                                            <!--<input placeholder="TOD Zone 4" name="tod4" formControlName="tod4"
                                                autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF15.tod4.errors }" required>
                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_4_Config">
                                                TOD Zone 3</label>
                                            <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF15.tod4.errors" class="invalid-feedback">
                                                <div *ngIf="aF15.tod4.errors.required">
                                                    Tod Zone 4 Is required
                                                </div>
                                            </div>-->
                                        </span>
                                    </div>
                                </div>

                                <div class="col-sm-6 ">
                                    <div class="form-group input-group">
                                        <span class="has-float-label">
                                            <input type="text" matInput placeholder="Week Days" name="weekDay"
                                                formControlName="weekDay" autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF23.weekDay.errors }" required>
                                            <label>
                                                Week Day</label>
                                            <i class="fa fa-clock-o icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF23.weekDay.errors" class="invalid-feedback">
                                                <div *ngIf="aF23.weekDay.errors.required">
                                                    Week Day Is required
                                                </div>
                                            </div>
                                            <!--<input placeholder="TOD Zone 5" name="tod5" formControlName="tod5"
                                                autocomplete="off" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && aF15.tod5.errors }" required>

                                            <label
                                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_5_Config">TOD
                                                Zone 5</label>
                                            <i class="fa fa-unlock-alt icon_innertxtbox"></i>
                                            <div *ngIf="submitted && aF15.tod5.errors" class="invalid-feedback">
                                                <div *ngIf="aF15.tod5.errors.required">
                                                    Tod Zone 5 required
                                                </div>
                                            </div>-->
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row iconconfigbtn">
                                <button class="btnref" [disabled]="isLoading" style="border: 0;background: azure;"
                                    (click)="loadCapturePeriod('23')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_TOD_Zone_Read_Config">
                                    <i class="fa d-c4"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading23, 'fa-refresh': !isLoading23}"
                                        style="font-size: 20px;"></i>
                                </button>
                                <div class="">
                                    <button type="submit" [disabled]="isLoading" class="dilog dilog1 buttonStyle"
                                        (click)="submitForm('23')">
                                        <i class="fa"
                                            [ngClass]="{'fa-spin fa-spinner': isLoadingz23, 'fa-refresh': !isLoadingz23}"
                                            style="font-size: 15px;"></i>
                                        {{button23}}
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-12 row">
                                <div class="col-sm-12 text-left">
                                    <div role="alert" *ngIf="message23 && message23.length > 0">
                                        <div class="alert alert-success " role="alert" *ngIf="thumb">
                                            <mat-icon>thumb_up</mat-icon>&nbsp;{{message23}}
                                        </div>
                                        <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                            <mat-icon>thumb_down</mat-icon>&nbsp;{{message23}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <form [formGroup]="actionForm16">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Phase_Config && (meterDetail.meterType=='3' || meterDetail.meterType=='2')">
                        <legend class="scheduler-border">
                            ESW Filter
                        </legend>
                        <div class="row">
                            <table>
                                <tr>
                                    <td style="width:92%;text-align-last: start;">
                                        <B>ESW Filter Three Phase</B>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config || 
                                                            getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config || 
                                                            getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config">
                                <mat-radio-group name="rPhaseVoltage" formControlName="rPhaseVoltage">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">
                                                    1) R-Phase Voltge Missing
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config">
                                <mat-radio-group name="yPhaseVoltage" formControlName="yPhaseVoltage">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    2) Y-Phase Voltge Missing
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config">
                                <mat-radio-group name="bPhaseVoltage" formControlName="bPhaseVoltage">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    3) B-Phase Voltge Missing
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config">
                                <mat-radio-group name="overVoltage" formControlName="overVoltage">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    4) Over Voltage
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config">
                                <mat-radio-group name="lowVoltage" formControlName="lowVoltage">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">
                                                    5) Low Voltage
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config">
                                <mat-radio-group name="voltageUnbalance" formControlName="voltageUnbalance">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    6) Voltage Unbalance
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config || 
                                                                getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config || 
                                                                getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config">
                                <mat-radio-group name="rPhaseCurrentRev" formControlName="rPhaseCurrentRev">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    7) R-Phase Currrent Reverse
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config">
                                <mat-radio-group name="yPhaseCurrentRev" formControlName="yPhaseCurrentRev">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    8) Y-Phase Currrent Reverse
                                                </td>
                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config">
                                <mat-radio-group name="bPhaseCurrentRev" formControlName="bPhaseCurrentRev">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">
                                                    9) B-Phase Currrent Reverse
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config || 
                                                            getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config || 
                                                            getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config">
                                <mat-radio-group name="currentUnbalance" formControlName="currentUnbalance">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    10) Current Unbalance
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config">
                                <mat-radio-group name="currentByPass" formControlName="currentByPass">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    11) Current bypass/short
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config">
                                <mat-radio-group name="overCurrent" formControlName="overCurrent">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    12) Over Current (Any-Phase)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Influb_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config">
                                <mat-radio-group name="veryLPF" formControlName="veryLPF">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">
                                                    13) Very Low PF
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Influb_Config">
                                <mat-radio-group name="infMagnet" formControlName="infMagnet">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    14) Influence Of Magnet
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config">
                                <mat-radio-group name="neturalDistrubance" formControlName="neturalDistrubance">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    15) Neutral Disturbance
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config">
                                <mat-radio-group name="meterCOpen" formControlName="meterCOpen">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    16) Meter Cover Open
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config">
                                <mat-radio-group name="loadConnectDis" formControlName="loadConnectDis">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    17) Load Connect/Disconnect
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config">
                                <mat-radio-group name="lastGasp" formControlName="lastGasp">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    18) Last Gasp
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config">
                                <mat-radio-group name="firstBreath" formControlName="firstBreath">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    19) First Breath
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config">
                                <mat-radio-group name="incBillingCount" formControlName="incBillingCount">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">
                                                    20) Manual MD Reset
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config">

                            </div>
                        </div>
                        <div class="iconconfigbtn">
                           
                            <div style="float:right;">
                                <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoadingz14"
                                    (click)="loadCapturePeriod('14')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Read_Config">
                                    <i class="fa fa-download" style="font-size: 25px;"></i>
                                </button>
                            </div>

                            
                            <div style="float:right;">
                                <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoadingz14"
                                    (click)="submitForm('14')"
                                    *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Write_Config">
                                    <i class="fa fa-upload" style="font-size: 25px;"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-sm-12 text-left">
                                <ng-container *ngIf="isLoadingz14">
                                    <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                                </ng-container>
                                <div role="alert" *ngIf="message14 && message14.length > 0">
                                    <div class="alert alert-success " role="alert" *ngIf="thumb">
                                        <mat-icon>thumb_up</mat-icon>&nbsp;{{message14}}
                                    </div>
                                    <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                        <mat-icon>thumb_down</mat-icon>&nbsp;{{message14}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <form [formGroup]="actionForm17" (ngSubmit)="submitForm('15')">
                    <fieldset class="scheduler-border"
                        *ngIf="getThrough.authority && getThrough.authority.Meters_Sub_Configure_Utility_ESW_Filter_Single_Phase_Config && meterDetail.meterType=='1'">
                        <legend class="scheduler-border">
                            ESW Filter
                        </legend>
                        <div class="col-sm-12 row">
                            <table>
                                <tr>
                                    <td style="width:92%;text-align-last: start;">
                                        <B>ESW Filter Single Phase</B>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config">
                                <mat-radio-group name="earthLoading" formControlName="earthLoading">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">
                                                    1) Earth Loading
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config">
                                <mat-radio-group name="infMagnetS" formControlName="infMagnetS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    2) Influence Of Magnet
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config">
                                <mat-radio-group name="neturalDistrubanceS" formControlName="neturalDistrubanceS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    3) Neutral Disturbance
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config">
                                <mat-radio-group name="meterCOpenS" formControlName="meterCOpenS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    4) Meter Cover Open
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config">
                                <mat-radio-group name="loadConnectDisS" formControlName="loadConnectDisS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    5) Load Connect/Disconnect
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config">
                                <mat-radio-group name="lastGaspS" formControlName="lastGaspS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    6) Last Gasp
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config">
                                <mat-radio-group name="firstBreathS" formControlName="firstBreathS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    7) First Breath
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config">
                                <mat-radio-group name="incBillingCountS" formControlName="incBillingCountS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">
                                                    8) Manual MD Reset
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config">
                                <mat-radio-group name="currentReverse" formControlName="currentReverse">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    9) Current Reverse
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>

                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-sm-12 row"
                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config || 
                                                        getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config">
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config">
                                <mat-radio-group name="overVoltageS" formControlName="overVoltageS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    10) Over Voltage
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config">
                                <mat-radio-group name="lowVoltageS" formControlName="lowVoltageS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    11) Low Voltage
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                            </div>
                            <div class="col-sm-4 text-left"
                                *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config">
                                <mat-radio-group name="overCurrentS" formControlName="overCurrentS">
                                    <table>
                                        <div class="innertbl">
                                            <tr>
                                                <td colspan="3" style="text-align-last: start;">

                                                    12) Over Current
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="1">On</mat-radio-button>
                                                </td>
                                                <td style="background: white; text-align: -webkit-left;">
                                                    <mat-radio-button value="2">Off</mat-radio-button>
                                                </td>
                                            </tr>
                                        </div>
                                    </table>
                                </mat-radio-group>
                                <div class="iconconfigbtn">
                                   
                                    <div style="float:right;">
                                        <button title="Get" class="dilog dilog1 buttonStyle" [disabled]="isLoadingz15"
                                            (click)="loadCapturePeriod('15')"
                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Read_Config">
                                            <i class="fa fa-download" style="font-size: 25px;"></i>
                                        </button>
                                    </div>
                                    
                                    <div style="float:right;">
                                        <button title="Set" class="dilog dilog1 buttonStyle" [disabled]="isLoadingz15"
                                            (click)="submitForm('15')"
                                            *ngIf="getThrough.authority.Meters_Sub_Configure_Utility_ESW_Single_Write_Config">
                                            <i class="fa fa-upload" style="font-size: 25px;"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-sm-12 text-left">
                                <ng-container *ngIf="isLoadingz15">
                                    <fa-icon [icon]="faSpinner" [spin]="true" size="3x" class="makeCenter"></fa-icon>
                                </ng-container>
                                <div role="alert" *ngIf="message15 && message15.length > 0">
                                    <div class="alert alert-success " role="alert" *ngIf="thumb">
                                        <mat-icon>thumb_up</mat-icon>&nbsp;{{message15}}
                                    </div>
                                    <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                        <mat-icon>thumb_down</mat-icon>&nbsp;{{message15}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>

                
            </div>
        </ng-container>
    </div>