import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatStepper } from '@angular/material/stepper';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-meter-recharge',
  templateUrl: './meter-recharge.component.html',
  styleUrls: ['./meter-recharge.component.scss']
})
export class MeterRechargeComponent implements OnInit {
  public today = new Date();
  thumb = true;
  faSpinner = faSpinner;

  mode = [
    { name: 'Cash' },
    { name: 'Credit Card' },
    { name: 'Debit Card' },
    { name: 'Online Recharge' },
    { name: 'NEFT' },
    { name: 'Transfer Amount' }
  ];
  isLoading = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  isLinear = true;
  rechargeFormGroup: UntypedFormGroup;
  otpFormGroup: UntypedFormGroup;
  userInfo: any = {};
  responseMessage: string = '';
  consFullName: string = '';
  consEmail: string = '';
  consMobileId: string = '';
  responseData: any;
  invalidMessage: any = '';
  reviewSubmitMessage = '';
  reviewSubmitStatus: boolean = false;
  submitted: boolean = false;
  meterDetail: any = '';
  flatNo: any = '';
  pickedDate = new Date();
  picked: any = '';
  rechageModeValue: any = '';
  divTagOne = 'col-sm-4 text-left';
  divTagTwo = 'col-sm-8 text-right';
  emonth = 0; //always move 1 value up
  eday = 0;
  eyear = 0;
  ehour = 0;
  eminute = 0;
  esecond = 0;

  emonthNew = ''; //always move 1 value up
  edayNew = '';
  eyearNew = '';
  ehourNew = '';
  eminuteNew = '';
  esecondNew = '';
  disableButton: boolean = false;
  disableBackButton: boolean = false;
  meterData = [{
    "consFullName": "null",
    "consEmail": "null ",
    "consMobileId": "0000"
  }]

  processData = '';
  paymentMode: string[] = [
    "id",
    "paymentModeCode",
    "paymentModeName",
    "inserDateTime",
    "authId"
  ];

  rechargeType = [
    { "id": "0", "rechargeName": "Electricity " }
    //{ "id": "1", "rechargeName": "Maintenance " }
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.emonthNew = (this.pickedDate.getMonth() + 1).toString(); //always move 1 value up
    this.edayNew = this.pickedDate.getDate().toString();
    this.eyearNew = this.pickedDate.getFullYear().toString();
    this.ehourNew = this.pickedDate.getHours().toString();
    this.eminuteNew = this.pickedDate.getMinutes().toString();
    this.esecondNew = this.pickedDate.getSeconds().toString();

    //console.log(this.emonthNew.length," ",this.edayNew.length," ",this.eyearNew.length," ",this.ehourNew.length," ",this.eminuteNew.length," ",this.esecondNew.length);
    if (this.emonthNew.length == 1) {
      0
      this.emonthNew = "0" + this.emonthNew.toString();
    }
    if (this.edayNew.toString.length == 1) {
      this.edayNew = "0" + this.edayNew.toString();
    }
    if (this.eyearNew.toString.length == 1) {
      this.eyearNew = "0" + this.eyearNew.toString();
    }
    if (this.ehourNew.toString.length == 1) {
      this.ehourNew = "0" + this.ehourNew.toString();
    }
    if (this.eminuteNew.toString.length == 1) {
      this.eminuteNew = "0" + this.eminuteNew.toString();
    }
    if (this.esecondNew.toString.length == 1) {
      this.esecondNew = "0" + this.esecondNew.toString();
    }
    this.picked = this.edayNew + '/' + this.emonthNew + '/' + this.eyearNew + ' ' + this.ehourNew + ':' + this.eminuteNew + ':' + this.esecondNew;

    this.paymentModeList();
    //console.log("hello");
    this.flatNo = this.meterDetail.flatNo;
    this.consFullName = this.meterDetail.consFullName;
    this.consEmail = this.meterDetail.consEmail;
    this.consMobileId = this.meterDetail.consMobileId;
    if (this.meterDetail.sdID == 87) {
      this.rechargeFormGroup = this.formBuilder.group({
        rechargeType: ['', Validators.required],
        meterId: ['', Validators.required],
        rechargeAmount: ['', [Validators.required, Validators.min(1), Validators.max(99999)]],
        //transactionId: ['', Validators.required],
        rechargeMode: ['', Validators.required],
        remark: ['']
      });
    } else {
      this.rechargeFormGroup = this.formBuilder.group({
        meterId: ['', Validators.required],
        rechargeAmount: ['', [Validators.required, Validators.min(1), Validators.max(99999)]],
        //transactionId: ['', Validators.required],
        rechargeMode: ['', Validators.required],
        remark: ['']
      });
    }
    this.otpFormGroup = this.formBuilder.group({
      otp: ['', Validators.required]
    });
    this.rF.meterId.setValue(this.meterDetail.mID);

  }

  get rF() { return this.rechargeFormGroup.controls; }
  get oF() { return this.otpFormGroup.controls; }


  value(value) {
    this.rechargeType = value;
  }

  generateOtp() {
    if (this.rechargeFormGroup.invalid) {
      this.submitted = true;
      return;
    }
    let body = new URLSearchParams();
    body.set('subdevid', this.meterDetail.sdID);
    body.set('mobileNo', this.meterDetail.consMobileId);
    body.set('emailId', this.meterDetail.consEmail);
    body.set('activeStatus', this.meterDetail.mActiveStatus);
    body.set('subDevisionDatabaseName', this.meterDetail.subDivDatabase);
    body.set('rechargeAmount', this.rF.rechargeAmount.value);
   
    body.set('type', 'Recharge');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/sendRechargeOtp', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.meterData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.responseMessage = '<div class="errors">Unable to process yours request!</div>';
    });
  }

  matchOtp(stepper) {
    if (this.otpFormGroup.invalid) {
      this.submitted = true;
      return;
    }
    let body = new URLSearchParams();
    body.set('subdevid', this.meterDetail.sdID);
    body.set('mobileNo', this.meterDetail.consMobileId);
    body.set('emailId', this.meterDetail.consEmail);
    body.set('activeStatus', this.meterDetail.mActiveStatus);
    body.set('otpValue', this.oF.otp.value);
    
    body.set('subDevisionDatabaseName', this.meterDetail.subDivDatabase);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/matchRechargeDomainOtp', body).subscribe((result) => {
      this.responseData = result;
      //console.log("-->",this.responseData);
      if (this.responseData.success) {
        //this.responseMessage = this.responseData.message;
        //console.log("-->",this.responseMessage);
        this.goForward(stepper);
      } else {
        this.invalidMessage = '<div class="errors">' + this.responseData.message + '</div>';
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.responseMessage = '<div class="errors">Unable to process yours request!</div>';
    });

  }

  goForward(stepper: MatStepper) {
    //console.log("--function called");
    stepper.next();
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
    stepper.previous();
  }

  processRechage() {
    this.disableButton = true;
    this.disableBackButton = true;
    this.isLoading = true;
    this.reviewSubmitMessage = '';
    this.reviewSubmitStatus = false;
    let body = new URLSearchParams();
    body.set('subdivId', this.meterDetail.sdID);
    body.set('consId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('meterSerialNo', this.meterDetail.mID);
    body.set('meterIpAddress', this.meterDetail.mIPAdd);
    body.set('meterPortNo', this.meterDetail.mPortNo);
    body.set('rechargeAmount', this.rF.rechargeAmount.value);
    body.set('rechargeMode', this.rF.rechargeMode.value);
    body.set('remark', this.rF.remark.value);
    body.set('subDevisionDatabaseName', this.meterDetail.subDivDatabase);
    if (this.meterDetail.sdID == 87) {
      body.set('rechargeType', this.rF.rechargeType.value);
    }else{
      body.set('rechargeType','0');
    }
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/processRacharge', body).subscribe((result) => {
      this.responseData = result;
      //this.reviewSubmitMessage = this.responseData.data.message;
      //this.reviewSubmitStatus = this.responseData.success;
      this.divTagOne = 'col-sm-8 text-left';
      this.divTagTwo = 'col-sm-4 text-right';
      this.isLoading = false;
      if (this.responseData.success) {
        this.disableButton = false;
        this.disableBackButton = false;
        this.reviewSubmitStatus = true;
        this.thumb = true;
        this.reviewSubmitMessage = this.responseData.data.message;
        if (this.reviewSubmitMessage.includes("Not Updated") ||
          this.reviewSubmitMessage.includes("not updated") ||
          this.reviewSubmitMessage.includes("You are not authorize") ||
          this.reviewSubmitMessage.includes("Communication Failure") ||
          this.reviewSubmitMessage.includes("Un-Successfully") ||
          this.reviewSubmitMessage.includes("Unsuccessfully") ||
          this.reviewSubmitMessage.includes("Under Progress") ||
          this.reviewSubmitMessage.includes("Error") ||
          this.reviewSubmitMessage.includes("ERR")) {
          this.thumb = false;
        } else {
          this.thumb = true;
        }
        //this.modal.close('done');
      }

      // this.processData = this.responseData.data;
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.reviewSubmitMessage = 'Unable to process yours request!';
      this.reviewSubmitStatus = false;
      this.isLoading = false;
    });
  }

  /**to get the meter list on click on Dcu */
  meterView(row, addNew: boolean = false) {
    let body = new URLSearchParams();
    body.set('subdivId', this.currentUser.data.userData.subdivision);
    body.set('dcuId', this.currentUser.data.userData.dcu);
    body.set('authToken', this.currentUser.authToken);
    body.set('offSet', '0');
    body.set('limit', '9999999');
    this.genReqs.postReq('/getMeters', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.meterData = this.responseData.data;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  paymentModeList() {

    let body = new URLSearchParams();
    body.set('subDevisionDatabaseName', this.meterDetail.subDivDatabase);
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getPaymentRechargeModes', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.paymentMode = this.responseData.data;
      }
      // console.log("-->",this.responseData.data);
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
    });
  }

  printRechargeInfo() {
    let printContents, popupWin;
    printContents = document.getElementById('recharge_review').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Recharge Info</title>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  onSelectFlat(e, rechageModeValue) {
    this.rechageModeValue = rechageModeValue;
  }

}