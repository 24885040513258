export class Access {
        'consumer' : {

                deshboard_Map_View: 0,
                Cons_Griphical_Desh_Grid_Utility_View: 0,
                Cons_Griphical_User_Consumption_Chart: 0, 
                Cons_Meter_DG_Column: 0, 
                Cons_Meter_Tower_Column: 0, 
                Cons_Meter_Group_Column: 0, 
                Cons_Meter_HES_Id_Column: 0, 
                Cons_Griphical_Desh_Grid_Society_kWH_View: 0, 
                Cons_Griphical_Desh_Grid_Society_DG_kWH_View: 0, 
                Cons_Griphical_Desh_Grid_Society_Recharge_View: 0, 
                Cons_Griphical_Desh_Grid_Utility_kWH_View: 0, 
                Cons_Griphical_Desh_Grid_Utility_kVARH_View: 0, 
		Cons_Griphical_Desh_Grid_Utility_kVAH_View: 0, 
		Cons_Griphical_User_Consumption_Daily: 0, 
		Cons_Griphical_User_Consumption_Monthly: 0,
		Cons_Griphical_User_Consumption_Yearly: 0,
		Cons_Griphical_Power_Cut_Chat: 0,
		Cons_Griphical_Power_Cut_Daily: 0,
		Cons_Griphical_Power_Cut_Monthly: 0,
		Cons_Griphical_Power_Cut_Yearly: 0,
		Cons_Cons_UId_Column: 0,
		Cons_Meter_UId_Column: 0,
		Cons_Flat_Id_Column: 0,
		Cons_Meter_No_Column: 0,
		Cons_Meter_Ip_Column: 0,
		Cons_Meter_Type_Column: 0,
		Cons_LL_Grid_kW_Column: 0,
		Cons_LL_DG_kW_Column: 0,
		Cons_Last_Update_Column: 0,
		Cons_Grid_Voltage_R_Column: 0,
		Cons_Grid_Voltage_Y_Column: 0,
		Cons_Grid_Voltage_B_Column: 0,
		Cons_Grid_Phase_Current_R_Column: 0,
		Cons_Grid_Phase_Current_Y_Column: 0,
		Cons_Grid_Phase_Current_B_Column: 0,
		Cons_Grid_Nutural_Current_R_Column: 0,
		Cons_Grid_Nutural_Current_Y_Column: 0,
		Cons_Grid_Nutural_Current_B_Column: 0,
		Cons_Grid_PF_R_Column: 0,
		Cons_Grid_PF_Y_Column: 0,
		Cons_Grid_PF_B_Column: 0,
		Cons_Grid_Total_Power_Factor_Column: 0,
		Cons_Grid_Frequency_Column: 0,
		Cons_Grid_Active_Power_Column: 0,
		Cons_Grid_Reactive_Power_Column: 0,
		Cons_Grid_ApperantPower_kVA_Column: 0,
		Cons_Grid_Md_Kw_Column: 0,
		Cons_Grid_Md_kVA_Column: 0,
		Cons_Grid_kWH_Column: 0, 
		Cons_Grid_kVARH_Q1_Column: 0, 
		Cons_Grid_kVARH_Q2_Column: 0, 
		Cons_Grid_kVARH_Q3_Column: 0, 
                Cons_Grid_kVARH_Q4_Column: 0, 
                Cons_Tamper_Count_Column: 0, 
                Cons_Billing_Count_Column: 0, 
		Cons_Grid_kVAH_Column: 0, 
		Cons_DG_kWH_Column: 0, 
		Cons_DG_kVAH_Column: 0, 
		Cons_Balance_Column: 0, 
		Cons_Supply_Column: 0, 
		Cons_Tamper_Column: 0, 
		Cons_Last_LS_DT_Column: 0, 
		Cons_Last_Block_LS_DT_Column: 0, 
		Cons_Relay_Status_Column: 0, 
		Cons_Live_Status_Column: 0, 
		Cons_Action_Column: 0,             
                Cons_Real_Time_Status_Access: 0,
                Cons_On_Going_Consumption_Access: 0,
                Cons_Daily_Consumption_Access: 0,
                Cons_Recent_Recharge_Access: 0,
                Cons_Recent_Adjustment_Access: 0,
                Cons_Recent_Configuration_Access: 0,
                Cons_Recent_Bill_Access: 0,
                Cons_Recent_Event_Access: 0,
                Cons_Recent_Activity_Access: 0,
                Cons_Recent_SMS_Sent_Access: 0,
                Cons_Recent_Complaints_Access: 0,
                Cons_Menu_Action_Access: 0,
                Cons_Action_Load_Limiter_Access: 0,
                Cons_Action_Meter_Load_Access: 0,
                Recharge_Menu_Access: 0,
                Reports_Menu: 0,
                Report_Recharge_Data_Access: 0,
                Report_Adjustment_Data_Access: 0,
                Report_Bill_Data_Access: 0,
                Report_On_Going_Access: 0,
                Report_Daily_Consumption_Access: 0,
                Report_Monthly_Consumption_Access: 0,
                Report_Event_Log_Access: 0,
                Report_Meters_Activity_Log_Access: 0,
                Reports_SMS_Sent_Access: 0,
                Reports_Messages_Access: 0,
                Reports_Complaint_History_Access: 0,
                Reports_Login_Logout_Access: 0,
                Setting_Menu_Access: 0,
                Setting_Current_Teriff_Access: 0,
                Setting_load_limiter_Access: 0,
                Setting_Notification_Setting_Access: 0,
                Help_Menu_Access: 0,
                Help_Add_New_Complaint_Access: 0,
                Help_Existing_Complaint_Access: 0,
        };
        'authority' : {
                Meter_Sub_Meter_Daily_Consumptiion_Button_Pvt: 0,
                Meter_Sub_Meter_OnGoing_Consumption_New_Button_Pvt: 0,
                Meter_Sub_Meter_Recent_Other_Events_Button: 0,
                Meter_Sub_Meter_Recent_Voltage_Events_Button: 0,
                Meter_Sub_Meter_Recent_Current_Events_Button: 0,
                Meter_Sub_Meter_Recent_Non_Roll_Events_Button: 0,
                Meter_Sub_Meter_Recent_Control_Events_Button: 0,
                Meter_Sub_Meter_Recent_Power_Events_Button: 0,
                Meter_Sub_Meter_Recent_Transaction_Events_Button: 0,
                Meter_Sub_Meter_Day_Consumptiion_Button: 0,
                Meter_Sub_Meter_OnGoing_Consumption_New_Button: 0,
                Meter_Sub_Meter_Inst_Push_Button: 0,
                Auth_Sub_Menu_Sub_Old_New_Meter_Govt: 0,
                Auth_Sub_Menu_Sub_Old_New_Meter_Pvt: 0,
                Auth_Non_Communicated_Govt: 0,
                Auth_Non_Communicated_Pvt: 0,
                Meters_Reports_SLA_Govt_Report: 0,
                Meters_Reports_SLA_Pvt_Report: 0,
                Meter_Sub_Meter_Recent_Inst_Data_Button: 0,
                Meter_Sub_Meter_Activate_Emergency_Credit_Button: 0,
                Meter_Sub_Meter_Recent_Bills_Data_Button: 0,
                Meters_Sub_Configure_Utility_TOD_Zone_Config_1: 0,
                Meters_Sub_Configure_Utility_TOD_Zone_Config_2: 0,
                Meters_Sub_Configure_Utility_TOD_Zone_Config_3: 0,
                Meters_Sub_Configure_Utility_TOD_Zone_Config_4: 0,
                Meters_Sub_Configure_Utility_TOD_Zone_Config_5: 0,
                Meters_Sub_Configure_Utility_TOD_Zone_Config_6: 0,
                Meters_Sub_Configure_Utility_TOD_Zone_Config_7: 0,
                Meters_Sub_Configure_Utility_TOD_Zone_Config_8: 0,
                Meter_Sub_Meter_Name_Plate_Button: 0,
                Meters_Sub_Configure_Utility_Meter_Config: 0,
                Meters_Sub_Configure_Utility_Meter_Teriff: 0,
                Utility_Config_Single_Domestic: 0,
                Utility_Config_Single_Commercial: 0,
                Event_Push_data_Report: 0,
                Config_Firmware_Version: 0,

                Push_data_Report: 0,
                Removed_Meter_Report: 0,
                progress: 0,
                Billing_Integration: 0,
                Meters_Supply_Payments: 0,
                Meters_Integration_Payments: 0,
                Meters_Fms_Sim_Payments: 0,
                Meters_Hes_Payments: 0,

                Meter_Sub_Meter_Nameplate_Button: 0,

                Auth_Integration_And_commissioning: 0,
                Auth_Integration_And_commissioning_Add: 0,
                Auth_Integration_And_commissioning_Edit: 0,
                Auth_Integration_And_commissioning_Delete: 0,

                Auth_Check_Meter: 0,
                Auth_Sub_Action: 0,
                Auth_Avon_Meters_Display: 0,
                Auth_Sub_Menu_Sub_Old_New_Meter_Desh_HPL: 0,
                Auth_Sub_Menu_Sub_Old_New_Meter_Desh_Elide: 0,
                Auth_Sub_Zone_Wise_Search: 0,
                Auth_Sub_Circle_Wise_Search: 0,
                Auth_Sub_Division_Wise_Search: 0,
                Auth_Sub_SubDivision_Wise_Search: 0,
               
                
                Auth_All_Meter_Installed_Status_Pnb: 0,
                Auth_Sub_Menu_Sub_Old_New_Meter_Desh: 0,
                Auth_All_Grid_Deshboard: 0,
                Auth_All_Smart_Meter_Summery_Deshboard: 0,
                pending_payment_view: 0,
                smart_meter_deshboard_View: 0,
                deshboard_Map_View: 0,
                smart_meter_order_add: 0,
                smart_meter_order_view_serial_no: 0,
                smart_meter_order_add_serial_no: 0,
                smart_meter_order_edit_by_production: 0,
                smart_meter_order_edit_by_account: 0,
                smart_meter_order_edit_by_marketing: 0,
                smart_meter_order_delete_order: 0,
                smart_meter_deshboard_loading: 0, 
		Meters_Sub_Configure_Periodic_Push: 0,
                Admin_User_Remark_Revert: 0,
                Admin_Non_Comm_Count: 0,
                Admin_User_Remark_Revert_ADD_Button: 0,
                Admin_User_Remark_Revert_Edit_Button: 0,
                Admin_User_Remark_Remove_Button: 0,
                Meters_Reports_Day_Wise_Non_Comunicated_Report: 0,
                Meters_Reports_PD_Meters_Report: 0,
                Auth_Non_Communicated: 0, 
                Auth_Non_Com_Circle: 0, 
                Auth_Non_Com_Authority: 0, 
                Auth_Non_Com_Catergory: 0, 
                Auth_Non_Com_Date: 0, 
                Auth_Non_Com_Sim_Type: 0, 


                Meters_Sub_Disabled_Meters: 0,
                Auth_Menu: 0,

                Auth_All_Griphical_Desh_Grid_Society_View: 0,
                Auth_All_Griphical_Desh_Grid_Utility_View: 0,

                Meters_Reports_Old_New_Meters_Information_Report: 0,
                Meters_Reports_Day_Wise_Meters_Information_Report: 0,
                Meters_Reports_Replaced_Meters_Information_Report: 0,

                Auth_All_Griphical_Society_Deshboard:0,

                Meters_Sub_Configure_Load_Limit_On_Meter_3_PH: 0,
                Meters_Sub_Configure_Bill_date: 0,
                Meters_Sub_Configure_Relay_Control: 0,
                Meters_Sub_Configure_ESW_1_PH: 0,
                Meters_Sub_Configure_ESW_3_PH:0,

                Auth_Sub_Menu_Sub_Old_New_Meter: 0,
                Auth_Sub_Menu_Sub_Old_Meter_Modify_Button: 0,
                Auth_Sub_Menu_Sub_Old_Meter_Image_Button: 0,
                Auth_Sub_Menu_Sub_New_Meter_Image_Button: 0,
                Auth_All_Meter_Installed_Circle:0,
                Auth_All_Meter_Installed_Authority: 0,

                Auth_All_Meter_Single_Ph_Show: 0,
                Auth_All_Meter_Three_Ph_Show: 0,
                Auth_All_Meter_LTCT_Ph_Show: 0,

                Auth_All_Griphical_grid_kWH: 0,
                Auth_All_Griphical_dg_kWH: 0,
                Auth_All_Griphical_recharges: 0,
                Auth_All_Griphical_grid_kWH_Utility: 0,
                Auth_All_Griphical_grid_kWH_Monthly_Cons_Utility: 0,
                Auth_All_Griphical_grid_kWH_Daily_Cons_Utility: 0,

                Auth_All_Griphical_grid_kVARH_Utility: 0,

                Auth_All_Griphical_grid_kVAH_Utility: 0,
                Auth_All_Griphical_grid_kVAH_Monthly_Cons_Utility: 0,
                Auth_All_Griphical_grid_kVAH_Daily_Cons_Utility: 0,
                Auth_Sub_Menu_Sub_Edit_Amount_Refund_Button: 0,
                Auth_All_Griphical_User_Consumption_Chart: 0,
                Auth_All_Griphical_User_Consumption_Daily: 0,
                Auth_All_Griphical_User_Consumption_Monthly: 0,
                Auth_All_Griphical_User_Consumption_Yearly: 0,
                Auth_All_Griphical_User_Consumption_kWH_Grid: 0,
                Auth_All_Griphical_User_Consumption_kVAH_Grid: 0,
                Auth_All_Griphical_User_Consumption_kVARH_Q1_Grid: 0,
                Auth_All_Griphical_User_Consumption_kVARH_Q2_Grid: 0,
                Auth_All_Griphical_User_Consumption_kVARH_Q3_Grid: 0,
                Auth_All_Griphical_User_Consumption_kVARH_Q4_Grid: 0,
                Auth_All_Griphical_User_Consumption_kWH_DG: 0,
                Auth_All_Griphical_User_Consumption_kVAH_DG: 0,
                Auth_All_Griphical_Desh_Meter_Statistics_Chart: 0,
                Auth_All_Griphical_Desh_Meter_Balance_Chart: 0,
                Auth_All_Griphical_Desh_Meter_Tamper_Chart: 0,
                Auth_All_Griphical_Power_Cut_Chat: 0,
                Auth_All_Griphical_Power_Cut_Daily: 0,
                Auth_All_Griphical_Power_Cut_Monthly: 0,
                Auth_All_Griphical_Power_Cut_Yearly: 0,

                Auth_All_Griphical_Desh_Meter_Statistics_Total_Meters: 0,
                Auth_All_Griphical_Desh_Meter_Statistics_Non_Cum_Meters: 0,
                Auth_All_Griphical_Desh_Meter_Statistics_Active_Meters: 0,
                Auth_All_Griphical_Desh_Meter_Statistics_InActive_Meters: 0,
                Auth_All_Griphical_Desh_Meter_Statistics_Power_Off_Meters:0,
                Auth_All_Griphical_Desh_Meter_Statistics_Tampered_Meters: 0,
                Auth_All_Griphical_Desh_Meter_Statistics_Connected_Meters: 0,
                Auth_All_Griphical_Desh_Meter_Statistics_Disconnected_Meters: 0,
               
                Auth_All_Grid_Voltage_R_Column: 0,
                Auth_All_Grid_Voltage_Y_Column: 0,
                Auth_All_Grid_Voltage_B_Column: 0,
                Auth_All_Grid_Phase_Current_R_Column: 0,
                Auth_All_Grid_Phase_Current_Y_Column: 0,
                Auth_All_Grid_Phase_Current_B_Column: 0,
                Auth_All_Grid_Nutural_Current_R_Column: 0,
                Auth_All_Grid_Nutural_Current_Y_Column: 0,
                Auth_All_Grid_Nutural_Current_B_Column: 0,
                Auth_All_Grid_PF_R_Column: 0,
                Auth_All_Grid_PF_Y_Column: 0,
                Auth_All_Grid_PF_B_Column: 0,
                Auth_All_Grid_Total_Power_Factor_Column: 0,
                Auth_All_Grid_Frequency_Column: 0,
                Auth_All_Grid_Active_Power_Column: 0,
                Auth_All_Grid_Reactive_Power_Column: 0,
                Auth_All_Grid_ApperantPower_kVA_Column: 0,
                Auth_All_Grid_Md_Kw_Column: 0,
                Auth_All_Grid_Md_kVA_Column: 0,
                Auth_All_Grid_kVARH_Q1_Column: 0,
                Auth_All_Grid_kVARH_Q2_Column: 0,
                Auth_All_Grid_kVARH_Q3_Column: 0,
                Auth_All_Grid_kVARH_Q4_Column: 0,
                Auth_All_Grid_kVAH_Column: 0,
                Auth_All_DG_kVAH_Column: 0,
  


                Auth_All_DG_Filter: 0,
                Auth_All_Tamper_Filter: 0,
                Auth_All_Group_Filter: 0,
                Auth_All_Meter_Filter: 0,
                Auth_All_Search_Meter_Id_Filter: 0,
                Auth_All_Cons_UId_Column: 0,

                Auth_All_Cons_DG_Column: 0,
                Auth_All_Cons_Tower_Column: 0,
                Auth_All_Cons_Group_Column: 0,

                Auth_All_Cons_UId_Column_View: 0,
                Auth_All_Cons_DG_Column_View: 0,
                Auth_All_Cons_Tower_Column_View: 0,
                Auth_All_Cons_Group_Column_View: 0,
                Auth_All_Meter_UId_Column_View: 0,
                Auth_All_Flat_Id_Column_View: 0,
                Auth_All_Meter_No_Column_View: 0,
                Auth_All_Meter_Ip_Column_View: 0,
                Auth_All_Hes_Id_Column_View: 0,
                Auth_All_Meter_Type_Column_View: 0,
                Auth_All_LL_Grid_kW_Column_View: 0,
                Auth_All_LL_DG_kW_Column_View: 0,
                Auth_All_Last_Update_Column_View: 0,
                Auth_All_Grid_Voltage_R_Column_View: 0,
                Auth_All_Grid_Voltage_Y_Column_View: 0,
                Auth_All_Grid_Voltage_B_Column_View: 0,
                Auth_All_Grid_Phase_Current_R_Column_View: 0,
                Auth_All_Grid_Phase_Current_Y_Column_View: 0,
                Auth_All_Grid_Phase_Current_B_Column_View: 0,
                Auth_All_Grid_Nutural_Current_R_Column_View: 0,
                Auth_All_Grid_Nutural_Current_Y_Column_View: 0,
                Auth_All_Grid_Nutural_Current_B_Column_View: 0,
                Auth_All_Grid_PF_R_Column_View: 0,
                Auth_All_Grid_PF_Y_Column_View: 0,
                Auth_All_Grid_PF_B_Column_View: 0,
                Auth_All_Grid_Total_Power_Factor_Column_View: 0,
                Auth_All_Grid_Frequency_Column_View: 0,
                Auth_All_Grid_Active_Power_Column_View: 0,
                Auth_All_Grid_Reactive_Power_Column_View: 0,
                Auth_All_Grid_ApperantPower_kVA_Column_View: 0,
                Auth_All_Grid_Md_Kw_Column_View: 0,
                Auth_All_Grid_Md_kVA_Column_View: 0,
                Auth_All_Grid_kWH_Column_View: 0,
                Auth_All_Grid_kVARH_Q1_Column_View: 0,
                Auth_All_Grid_kVARH_Q2_Column_View: 0,
                Auth_All_Grid_kVARH_Q3_Column_View: 0,
                Auth_All_Grid_kVARH_Q4_Column_View: 0,
                Auth_All_Grid_kVAH_Column_View: 0,
                Auth_All_DG_kWH_Column_View: 0,
                Auth_All_DG_kVAH_Column_View: 0,
                Auth_All_Balance_Column_View: 0,
                Auth_All_Maint_Column_View: 0,
                Auth_All_Supply_Column_View: 0,
                Auth_All_Tamper_Column_View: 0,
                Auth_All_Last_LS_DT_Column_View: 0,
                Auth_All_Last_Block_LS_DT_Column_View: 0,
                Auth_All_Tamper_Count_Column_View: 0,
                Auth_All_Billng_Count_Column_View: 0,
                Auth_All_Relay_Status_Column_View: 0,
                Auth_All_Live_Status_Column_View: 0,
                Auth_All_Fota_Status_Column_View: 0,
                Auth_All_DG_Supply_Status_Column: 0,
                Auth_All_Antenna_Column_View: 0,
                Auth_All_Sim_Type_Column_View: 0,
                Auth_All_Action_Column_View: 0,

                Auth_All_Meter_UId_Column: 0,
                Auth_All_Flat_Id_Column: 0,
                Auth_All_Meter_No_Column: 0,
                Auth_All_Meter_Ip_Column: 0,
                Auth_All_Hes_Id_Column: 0,
                Auth_All_Meter_Type_Column: 0,
                Auth_All_LL_Grid_kW_Column: 0,
                Auth_All_LL_DG_kW_Column: 0,
                Auth_All_Last_Update_Column: 0,
                Auth_All_Grid_kWH_Column: 0,
                Auth_All_DG_kWH_Column: 0,
                Auth_All_Balance_Column: 0,
                Auth_All_Supply_Column: 0,
                Auth_All_Tamper_Column: 0,
                Auth_All_Last_LS_DT_Column: 0,
                Auth_All_Last_Block_LS_DT_Column: 0,
                
                Auth_All_Relay_Status_Column: 0,
                Auth_All_Live_Status_Column: 0,
                Auth_All_Fota_Status_Column: 0,
                Auth_All_Antenna_Column: 0,
                Auth_All_Sim_Type_Column: 0,
                Auth_All_Action_Column: 0,
                Auth_All_Tamper_Count_Column: 0,
                Auth_All_Billng_Count_Column: 0,


                Auth_Sub_Menu_Add_New_Level: 0,
                Auth_Sub_Menu_Sub_Add_Level_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Level_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Level_Button: 0,
                
                Auth_Sub_Menu_Add_Authority: 0,
                Auth_Sub_Menu_Sub_Add_Authority_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Authority_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Permission_Authority_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Report_Permission_Authority_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Authority_Button: 0,

                Auth_Sub_Menu_Add_Contractor: 0,
                Auth_Sub_Menu_Sub_Add_Contractor_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Contractor_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Contractor_Button: 0,
                
                Auth_Sub_Menu_Add_DG: 0,
                Auth_Sub_Menu_Sub_Add_DG_Button: 0,
                Auth_Sub_Menu_Sub_Edit_DG_Button: 0,
                Auth_Sub_Menu_Sub_Delete_DG_Button: 0,

                Auth_Sub_Menu_Add_Meter_Firmware: 0,
                Auth_Sub_Menu_Sub_Add_Meter_Firmware_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Meter_Firmware_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Meter_Firmware_Button: 0,

                Auth_Sub_Menu_Add_Meter_Range: 0,
                Auth_Sub_Menu_Sub_Add_Meter_Range_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Meter_Range_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Meter_Range_Button: 0,
                
                Auth_Sub_Menu_Add_Tower: 0,
                Auth_Sub_Menu_Sub_Add_Tower_Button: 0,
                
                Auth_Sub_Menu_Sub_Edit_Tower_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Tower_Button: 0,
                
                Auth_Sub_Menu_Add_Group: 0,
                Auth_Sub_Menu_Sub_Add_Group_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Group_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Group_Button: 0,
                
                Auth_Sub_Menu_Add_Teriff: 0,
                Auth_Sub_Menu_Sub_Add_Teriff_Button: 0,
                Auth_Sub_Menu_Sub_Modify_Teriff_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Teriff_Button: 0,
                Auth_Sub_Menu_Add_Consumer_Notification: 0,
                Auth_Sub_Menu_Sub_Add_Notification_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Notification_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Notification_Button: 0,
                Auth_Sub_Menu_Add_New_Meter_Command: 0,
                Auth_Sub_Menu_Sub_Add_Meter_Command_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Meter_Command_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Meter_Command_Button: 0,
                Auth_Sub_Menu_Add_New_DCU_Command: 0,
                Auth_Sub_Menu_Sub_Add_DCU_Command_Button: 0,
                Auth_Sub_Menu_Sub_Edit_DCU_Command_Button: 0,
                Auth_Sub_Menu_Sub_Delete_DCU_Command_Button: 0,
                Auth_Sub_Menu_Reports: 0,
                Auth_Sub_Menu_Sub_All_Authority_Info_Report: 0,
                Auth_Sub_Menu_Sub_All_Authority_Wise_Info_Report:0,
                Auth_Sub_Menu_Sub_All_Authority_Wise_Login_Logout_Report: 0,
                Auth_Sub_Menu_Sub_All_Authority_Login_Logout_Report: 0,
                Auth_Sub_Menu_Sub_All_Country_Info_Report: 0,
                Auth_Sub_Menu_Sub_All_State_Info_Report: 0,
                Auth_Sub_Menu_Sub_All_Utility_Info_Report: 0,
                Auth_Sub_Menu_Sub_All_Circle_Info_Report: 0,
                Auth_Sub_Menu_Sub_All_Division_Info_Report: 0,
                Auth_Sub_Menu_Sub_All_Sub_Division_Info_Report: 0,
                Auth_Sub_Menu_Sub_Electricity_Tariff_History: 0,

                Auth_Sub_Menu_Add_New_Scheduler: 0,
                Auth_Sub_Menu_Sub_Add_Scheduler_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Scheduler_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Scheduler_Button: 0,
                
                Auth_Sub_Menu_Add_New_Sim_Details: 0,
                Auth_Sub_Menu_Sub_Add_Sim_Details_Button: 0,
                Auth_Sub_Menu_Sub_Edit_Sim_Details_Button: 0,
                Auth_Sub_Menu_Sub_Delete_Sim_Details_Button: 0,
                

                Auth_Sub_Menu_Add_Country: 0,
                Auth_Sub_Menu_Edit_Country: 0,
                Auth_Sub_Menu_Delete_Country: 0,
                Auth_Sub_Menu_Add_State: 0,
                Auth_Sub_Menu_Edit_State: 0,
                Auth_Sub_Menu_Delete_State: 0,
                Auth_Sub_Menu_Add_Utility: 0,
                Auth_Sub_Menu_Edit_Utility: 0,
                Auth_Sub_Menu_Delete_Utility: 0,
                Auth_Sub_Menu_Edit_Circle: 0,
                Auth_Sub_Menu_Delete_Circle: 0,
                Auth_Sub_Menu_Add_Division: 0,
                Auth_Sub_Menu_Edit_Division: 0,
                Auth_Sub_Menu_Delete_Division: 0,
                Auth_Sub_Menu_Add_Sub_Division: 0,
                Auth_Sub_Menu_Edit_Sub_Division: 0,
                Auth_Sub_Menu_Delete_Sub_Division: 0,
                DCU_Menu: 0,
                DCU_Sub_Add_DCU: 0,
                DCU_Sub_Edit_DCU: 0,
                DCU_Sub_Delete_DCU: 0,
                DCU_Sub_Configure_DCU: 0,
                DCU_Sub_Configure_Sub_Config_Server_Details_On_DCU: 0,
                DCU_Sub_Configure_Sub_Config_Meter_Routers_On_DCU: 0,
                DCU_Sub_Configure_Sub_Config_Schedular_On_DCU: 0,
                DCU_Sub_Configure_Sub_Synchronized_DCU_RTC: 0,
                DCU_Sub_Activity_On_DCU: 0,
                DCU_Sub_Activity_Sub_Restart_DCU: 0,
                DCU_Sub_Activity_Sub_Shutdown_DCU: 0,
                DCU_Sub_Activity_Sub_Broadcast_Grid_Supply_From_DCU: 0,
                DCU_Sub_Activity_Sub_Broadcast_Backup_Supply_From_DCU: 0,
                DCU_Sub_Reporting_From_DCU: 0,
                DCU_Sub_Reporting_Sub_Get_Configuration_From_DCU: 0,
                DCU_Sub_Reporting_Sub_Get_Meter_Router_Details_From_DCU: 0,
                DCU_Sub_Reporting_Sub_Get_Scheduled_Demands_From_DCU: 0,
                DCU_Sub_Reporting_Sub_Get_Configured_Server_Detail_From_DCU: 0,
                DCU_Sub_Reporting_Sub_Get_Dcu_RTC_From_DCU: 0,
                DCU_Sub_Configure_Meter: 0,
                DCU_Sub_Configure_Load_Limit_On_Meter: 0,
                DCU_Sub_Configure_Billing_Teriff_On_Meter: 0,
                DCU_Sub_Configure_Push_Data_On_Meter: 0,
                DCU_Sub_Configure_Server_Details_On_Meter: 0,
                DCU_Sub_Configure_Display_Parameters_On_Meter: 0,
                DCU_Sub_Configure_Happy_Hours_On_Meter: 0,
                DCU_Sub_Configure_Emergeny_Credit_On_Meter: 0,
                DCU_Sub_Configure_Volt_Currnt_Thrashold_On_Meter: 0,
                DCU_Sub_Configure_Phase_Grid_On_Meter: 0,
                DCU_Sub_Configure_Phase_Backup_On_Meter: 0,
                DCU_Sub_Configure_DCU_Gateway_On_Meter: 0,
                DCU_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter: 0,
                DCU_Sub_Activity_On_Meter: 0,
                DCU_Sub_Activity_Sub_Servant_Mode_On_Off: 0,
                DCU_Sub_Activity_Sub_Meter_Load_On_Off: 0,
                DCU_Sub_Activity_Sub_Backup_Supply_On_Off: 0,
                DCU_Sub_Activity_Restart_Meter: 0,
                DCU_Sub_Reporting_From_Meter: 0,
                DCU_Sub_Reporting_Sub_Get_Inst_data: 0,
                DCU_Sub_Reporting_Sub_Get_Last_Night_Midnight_Data: 0,
                DCU_Sub_Reporting_Sub_Get_Complete_Midnight_Data: 0,
                DCU_Sub_Reporting_Sub_Get_Latest_Billing_Data: 0,
                DCU_Sub_Reporting_Sub_Get_Full_Billing_Data: 0,
                DCU_Sub_Reporting_Sub_Get_Latest_Tamper_Data: 0,
                DCU_Sub_Reporting_Sub_Get_Full_Tamper_Data: 0,
                DCU_Sub_Reporting_Sub_Get_Latest_Load_Survey_Data: 0,
                DCU_Sub_Reporting_Sub_Get_Full_Load_Survey_Data: 0,
                DCU_Sub_Reporting_Sub_Meter_Config_Data: 0,
                DCU_Sub_Reporting_Sub_Meter_Router_Config_Data: 0,
                DCU_Sub_Reporting_Sub_Meter_Hardware_Status: 0,
                DCU_Sub_Reports: 0,
                DCU_Sub_Reports_Sub_All_DCU_Info_Report: 0,
                DCU_Sub_Reports_Sub_All_DCU_Gateway_Config_Report: 0,
                DCU_Sub_Reports_Sub_All_DCU_Scheduled_Demand_Report: 0,
                DCU_Sub_Reports_Sub_All_DCU_Configured_Server_Details_Report: 0,
                DCU_Sub_Reports_Sub_All_DCU_RTC_Report: 0,
                Consumer_Menu: 0,
                Consumer_Sub_Add_Consumer: 0,
                Consumer_Sub_Add_Consumer_Button: 0,
                Consumer_Sub_Consumer_Recharge_Button: 0,
                Consumer_Sub_Consumer_Adjustment_Button: 0,
                Consumer_Sub_Edit_Consumer_Info_Button: 0,
                Consumer_Sub_Edit_Consumer_Permission_Button: 0,
                Consumer_Sub_Delete_Consumer_Button: 0,
                Consumer_Sub_Add_Consumer_Permissions: 0,
                Consumer_Sub_Add_Consumer_Complaint: 0,
                Consumer_Sub_Add_Consumer_Complaint_Button: 0,
                Consumer_Sub_Edit_Consumer_Complaint_Button: 0,
                Consumer_Sub_Delete_Consumer_Complaint_Button: 0,
                Consumer_Sub_Reports: 0,
                Consumer_Sub_All_Cons_Info_Report: 0,
                Consumer_Sub_All_Cons_Wise_Login_Logout_Report: 0,
                Consumer_Sub_All_Cons_Login_Logout_Report: 0,
                Consumer_Sub_All_Cons_Complaint_Report: 0,
                Consumer_Sub_All_Cons_Notifications_Report: 0,

                contractor_Menu: 0,
                contractor_add: 0,
                contractor_modify: 0,
                contractor_add_superviser: 0,
                contractor_delete: 0,
            
                contractor_superviser: 0,
                contractor_modify_superviser: 0,
                contractor_delete_superviser: 0,
                contractor_view_superviser: 0,
                contractor_issued_meters_range_menu: 0,
                contractor_issued_meters_range: 0,
                contractor_modify_meters_range: 0,
                contractor_delete_meters_range: 0,
                Comulative_Fault_Report: 0,
                Removed_Meters_Report: 0,
                Stock_Analysis_Report: 0,
                User_Work_Info_Report: 0,
                contractor_progress: 0,
                contractor_modify_payment: 0,
                
                Contractor_Sub_Reports: 0,
                contractor_info_Report: 0,
                contractor_superviser_info_Report: 0,
                contractor_faulty_meters_Report: 0,
                contractor_installed_meters_report: 0,
                contractor_payment_pending_report: 0,
                contractor_payment_cleared_report: 0,
                
                Meters_Menu: 0,
                DCU_Sub_Add_Meter: 0,
                DCU_Sub_Edit_Meter: 0,
                DCU_Sub_Delete_Meter: 0,
                Meters_Sub_Configure_Meter: 0,
                Meters_Sub_Configure_Load_Limit_On_Meter: 0,
                Meters_Sub_Configure_Billing_Teriff_On_Meter: 0,
                Meters_Sub_Configure_Push_Data_On_Meter: 0,
                Meters_Sub_Configure_Server_Details_On_Meter: 0,
                Meters_Sub_Configure_Display_Parameters_On_Meter: 0,
                Meters_Sub_Configure_Happy_Hours_On_Meter: 0,
                Meters_Sub_Configure_Emergeny_Credit_On_Meter: 0,
                Meters_Sub_Configure_Volt_Currnt_Thrashold_On_Meter: 0,
                Meters_Sub_Configure_Phase_Grid_On_Meter: 0,
                Meters_Sub_Configure_Phase_Backup_On_Meter: 0,
                Meters_Sub_Configure_DCU_Gateway_On_Meter: 0,
                Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter: 0,
                Meters_Sub_Configure_Alarm_Emerg_Credit_Teriff_On_Meter_Slab:0,
                Meters_Sub_Activity_On_Meter: 0,
                Meters_Sub_Activity_Sub_Servant_Mode_On_Off: 0,
                Meters_Sub_Activity_Sub_Meter_Load_On_Off: 0,
                Meters_Sub_Activity_Sub_Backup_Supply_On_Off: 0,
                Meters_Sub_Activity_Sub_Emergency_On: 0,
                Meters_Sub_Activity_Sub_DG_Free_Units_On_Off: 0,
                Meters_Sub_Activity_Sub_Meter_Mode_Pre_Post: 0,
                Meters_Sub_Activity_Restart_Meter: 0,
                Meters_Sub_Reporting_From_Meter: 0,

                Meter_Sub_Meter_Download_Instant_Button: 0,
                Meter_Sub_Meter_Download_All_Billing_Button: 0,
                Meter_Sub_Meter_Download_Selective_Billing_Button: 0,
                Meter_Sub_Meter_Download_Block_Load_Survey_Button: 0,
                Meter_Sub_Meter_Download_Daily_Load_Survey_Button: 0,
                Meter_Sub_Meter_Download_All_Tamper_Button: 0,
                Meter_Sub_Meter_Download_All_Voltage_Event_Button: 0,
                Meter_Sub_Meter_Download_Selective_Voltage_Event_Button: 0,
                Meter_Sub_Meter_Download_All_Current_Event_Button: 0,
                Meter_Sub_Meter_Download_Selective_Current_Event_Button: 0,
                Meter_Sub_Meter_Download_All_Power_Event_Button: 0,
                Meter_Sub_Meter_Download_Selective_Power_Event_Button: 0,
                Meter_Sub_Meter_Download_All_Transaction_Event_Button: 0,
                Meter_Sub_Meter_Download_Selective_Transact_Event_Button: 0,
                Meter_Sub_Meter_Download_All_Other_Event_Button: 0,
                Meter_Sub_Meter_Download_Selective_Other_Event_Button: 0,
                Meter_Sub_Meter_Download_All_Cover_Open_Event_Button: 0,
                Meter_Sub_Meter_Download_All_Control_Event_Button: 0,
                Meter_Sub_Meter_Download_Selective_Control_Event_Button: 0,
                Meter_Sub_Meter_Download_Load_Limit_Config_Button: 0,
                Meter_Sub_Meter_Download_Happy_Hours_Config_Button: 0,
                Meter_Sub_Meter_Download_Alarm_Level_Config_Button: 0,
                Meter_Sub_Meter_Download_Billing_Date_Config_Button: 0,

                Meters_Sub_Reporting_Sub_Get_Last_Night_Midnight_Data: 0,
                Meters_Sub_Reporting_Sub_Get_Latest_Billing_Data: 0,
                Meters_Sub_Reporting_Sub_Get_Full_Billing_Data: 0,
                Meters_Sub_Reporting_Sub_Get_Latest_Tamper_Data: 0,
                Meters_Sub_Reporting_Sub_Get_Full_Tamper_Data: 0,
                Meters_Sub_Reporting_Sub_Get_Latest_Load_Survey_Data: 0,
                Meters_Sub_Reporting_Sub_Get_Full_Load_Survey_Data: 0,
                Meters_Sub_Reporting_Sub_Meter_Config_Data: 0,
                Meters_Sub_Reporting_Sub_Meter_Router_Config_Data: 0,
                Meters_Sub_Reporting_Sub_Meter_Hardware_Status: 0,
                Meters_Reports: 0,
                Meters_Reports_Meters_Information_Report: 0,
                Meters_Reports_Activity_Log_Report: 0,
                Meters_Reports_Authority_Wise_Activity_Log_Report: 0,
                Meters_Reports_Current_Data_Report: 0,
                Meters_Reports_Get_Midnight_Data_Report: 0,
                Meters_Reports_Latest_Billing_Data_Report: 0,
                Meters_Reports_Latest_Tamper_Data_Report: 0,
                Meters_Reports_Current_Load_Survery_Report: 0,
                Meters_Reports_Meter_Config_Log_Report: 0,
                Meters_Reports_Meter_Router_Config_Log_Report: 0,
                Meters_Reports_Meter_Hardware_Status_Report: 0,
                Meters_Reports_Daily_Unit_Consumption_Report: 0,
                Meters_Reports_Daily_Unit_Energy_Report: 0,
                Meters_Reports_Monthly_Unit_Consumption_Report: 0,
                Meters_Reports_Yearly_Unit_Consumption_Report: 0,
                Meters_Reports_Daily_Total_Revenue_Report: 0,
                Meters_Reports_Daily_Total_Revenue_Report_failed: 0,
                Meters_Reports_Monthly_Total_Revenue_Report: 0,
                Meters_Reports_Yearly_Total_Revenue_Report: 0,
                Meters_Reports_Daily_Low_Balance_Report: 0,
                Meters_Reports_Daily_Zero_Balance_Report: 0,
                Meters_Reports_Daily_Dead_Meters_Report: 0,

                Meters_Reports_SMS_Sent_Report: 0,
                Meters_Reports_Daily_DG_Status_Report: 0,
                Meters_Reports_Daily_Total_Adjustment_Report: 0,
                Meters_Reports_Daily_Total_Adjustment_Report_Failed: 0,
                Meters_Reports_Load_Limit_Config_Report: 0,
                Meters_Reports_Tariff_Config_Report: 0,
                Meters_Reports_Tariff_Config_Slab_Report: 0,
                Meters_Reports_Happy_Hours_Report: 0,
                Meters_Reports_Bill_Creation_Report: 0,
                Meters_Reports_Daily_OnGoing_Consumption_Report: 0,
                Meters_Sub_Reporting_Sub_Meter_Billing_Date_Config_Status: 0,
                Meters_Sub_Reporting_Sub_Meter_Alarm_Level_Config_Status: 0,
                Meters_Sub_Reporting_Sub_Meter_Happy_Hours_Config_Data: 0,
                Meters_Sub_Reporting_Sub_Meter_Load_Limit_Config_Data: 0,
                Meters_Sub_Reporting_Sub_Get_Instant_Data: 0,

                Meter_Sub_Add_Meter: 0,
                Meter_Sub_Modify_Meter: 0,
                Meter_Sub_Recharge_Button: 0,
                Meter_Sub_Adjustment_Button: 0,
                Meter_Sub_Configure_Meter_Button: 0,
                Meter_Sub_Activity_On_Meter_Button: 0,
                Meter_Sub_Activity_On_Meter_Load_On_Off_Button: 0,
                Meter_Sub_Activity_On_DG_Supply_On_Off_Button: 0,
                Meter_Sub_Activity_On_Emergency_Credit_On_Button: 0,
                Meter_Sub_Activity_On_Meter_MD_Reset_Button: 0,
                Meter_Sub_Activity_On_Meter_Meter_Mode_Prepost_Button: 0,
                Meter_Sub_Activity_On_Meter_DG_Free_Units_Button: 0,

                Meter_Sub_Meter_Download_Option_Button: 0,
                Meter_Sub_Meter_Real_Time_Data_Button: 0,
                Meter_Sub_Meter_Recent_Recharge_History_Button: 0,
                Meter_Sub_Meter_Recent_Adjustment_History_Button: 0,
                Meter_Sub_Meter_Recent_Bill_Button: 0,
                Meter_Sub_Meter_Recent_Bills_Button: 0,
                Meter_Sub_Meter_Recent_Events_Button: 0,
                Meter_Sub_Meter_OnGoing_Consumption_Button: 0,
                Meter_Sub_Meter_Daily_Consumptiion_Button: 0,
                Meter_Sub_Meter_Recent_Activity_Button: 0,
                Meter_Sub_Meter_Recent_SMS_Sent_Button: 0,
                Meter_Sub_Meter_Billing_Teriff_Status_Button: 0,
                Meter_Sub_Meter_Load_Limit_Config_Status_Button: 0,
                Meter_Sub_Meter_Happy_Hours_Config_Status_Button: 0,
                Meter_Sub_Meter_Billing_Scheduler_Status_Button: 0,
                Meter_Sub_Meter_Billing_Teriff_From_Meter_Button: 0,
                Meter_Sub_Meter_Load_Limit_Config_From_Meter_Button: 0,
                Meter_Sub_Meter_Happy_Hours_Config_From_Meter_Button: 0,
                Meter_Sub_Meter_Billing_Scheduler_From_Meter_Button: 0,
                Meter_Sub_Meter_Recent_Complaints_Button: 0,
                Meter_Sub_Meter_Delete_Meter_Button: 0,
                Meters_Sub_Configure_Utility_Meter:  0,
                Meters_Sub_Configure_Utility_Gernal_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_load_Profile_Captured_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Read_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_load_Pro_Cap_Write_Config:  0,
                Meters_Sub_Configure_Utility_Demand_Integration_Period_Config:  0,
                Meters_Sub_Configure_Utility_Demand_Int_Period_Read_Config:  0,
                Meters_Sub_Configure_Utility_Demand_Int_Period_Write_Config:  0,
                Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Config:  0,
                Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Read_Config:  0,
                Meters_Sub_Configure_Utility_Load_Limit_Thrashhold_Write_Config:  0,
                Meters_Sub_Configure_Utility_Billing_Date_Config:  0,
                Meters_Sub_Configure_Utility_Billing_Date_Read_Config:  0,
                Meters_Sub_Configure_Utility_Billing_Date_Write_Config:  0,
                Meters_Sub_Configure_Utility_Push_Schedule_Config:  0,
                Meters_Sub_Configure_Utility_Push_Schedule_Read_Config:  0,
                Meters_Sub_Configure_Utility_Push_Schedule_Write_Config:  0,
                Meters_Sub_Configure_Utility_Relay_Config:  0,
                Meters_Sub_Configure_Utility_Relay_Read_Config:  0,
                Meters_Sub_Configure_Utility_Relay_Write_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Prepaid_Net_Conn_Md_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Prepaid_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Prepaid_Read_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Prepaid_Write_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Net_Meter_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Net_Meter_Read_Config:  0,

                Meters_Sub_Configure_Utility_Gernal_Net_Meter_Write_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Read_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Con_Write_Config:  0,

                Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Read_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Conn_Dis_Write_Config:  0,


                Meters_Sub_Configure_Utility_Gernal_Md_Reset_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Md_Reset_Read_Config:  0,
                Meters_Sub_Configure_Utility_Gernal_Md_Reset_Write_Config:  0,
                Meters_Sub_Configure_Utility_Time_Config:  0,
                Meters_Sub_Configure_Utility_Time_Read_Config:  0,
                Meters_Sub_Configure_Utility_Time_Write_Config:  0,
                Meters_Sub_Configure_Utility_Password_Config:  0,
                Meters_Sub_Configure_Utility_Password_Low_Level_Write_Config:  0,
                Meters_Sub_Configure_Utility_Password_High_Level_Write_Config:  0,
                Meters_Sub_Configure_Utility_Enc_Auth_Key_Config:  0,
                Meters_Sub_Configure_Utility_Enc_Auth_Key_Write_Config:  0,
                Meters_Sub_Configure_Utility_ThrashHold_Limit_Config:  0,
                Meters_Sub_Configure_Utility_ThrashHold_Limit_Read_Config:  0,
                Meters_Sub_Configure_Utility_ThrashHold_Limit_Write_Config:  0,

                Meters_Sub_Configure_Utility_Load_Limiter_Config:  0,
                Meters_Sub_Configure_Utility_Load_Limiter_Read_Config:  0,
                Meters_Sub_Configure_Utility_Load_Limiter_Write_Config:  0,

                Meters_Sub_Configure_Utility_TOD_Zone_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_1_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_2_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_3_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_4_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_5_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_6_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_7_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_8_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_Read_Config:  0,
                Meters_Sub_Configure_Utility_TOD_Zone_Write_Config:  0,

                
                Meters_Sub_Configure_Utility_ESW_Three_Phase_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_R_Volt_Miss_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Y_Volt_Miss_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_B_Volt_Miss_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Over_Volt_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Low_Volt_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Volt_Unbalance_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_R_Current_Reverce_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Y_Current_Reverce_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_B_Current_Reverce_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Current_Unbalance_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Current_Bypass_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Current_OCurrent_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Low_PF_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Influb_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Nutural_Dis_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_C_Open_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_Load_C_Dis_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_Last_Gashp_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_First_Breath_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_Inc_Bill_Cnt_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Read_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Filter_Three_Phase_Write_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Filter_Single_Phase_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Earth_Loading_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Magnet_Influ_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Nutural_Distrob_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Meter_Cover_Open_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Load_Conn_Dis_Config:  0,

                Meters_Sub_Configure_Utility_ESW_Three_Meter_Md_Reset_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_Zero_Balance_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_Negative_Balance_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_Overload_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_EB_On_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Frequency_Abnormal_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Three_Meter_DG_On_Config:  0,


                Meters_Sub_Configure_Utility_ESW_Single_Meter_Last_Gashp_Config :  0,
                Meters_Sub_Configure_Utility_ESW_Single_Meter_First_Brea_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Mtr_Inc_Bill_Cnt_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Mtr_Curr_Reverse_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Over_Volt_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Low_Volt_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Over_Current_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Read_Config:  0,
                Meters_Sub_Configure_Utility_ESW_Single_Write_Config:  0,
                Meters_Reports_Old_New_Meters_Billing_Report:  0,


                Auth_All_Grid_Deshboard_Ltct:  0,
                Auth_All_Grid_Deshboard_Three:  0,
                Auth_All_Grid_Deshboard_Single:  0,
                Meters_Sub_Configure_Meter_Utility:  0,
                Contractor_Deshboard:  0,
                Superviser_Deshboard:  0,
                Auth_All_Meter_Installed_Status_New:  0,
                Updation_date_Time: '',
                Server_Date_Time: '',
                Authirity_Id: 0
        };
}