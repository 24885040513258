<div class="modal-header subpage">
  <h4 class="modal-title" id="modal-basic-title">
    <button type="button" *ngIf="this.meterDetail.srNo" (click)="modal.close('Ok click')" class="close" class="close"
      data-dismiss="modal">&times;</button>
    Download Meter: {{meterDetail.mID}}
  </h4>
</div>

<div class="modal-body">
  <ng-container class="allutilitysloader col-12" *ngIf="loading">
    <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
  </ng-container>
  <ng-container>

  </ng-container>
  <ng-container>
    <div class="col-sm-12 col-md-12 mx-auto form-wrapper">
      <form [formGroup]="reportForm">
        <!--<form [formGroup]="reportForm" (ngSubmit)="onSubmitAddForm()" >-->
        <mat-radio-group name="reporting" formControlName="reporting" (change)="onItemChange($event)"
          class="outerrportingflex">
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Instant_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx" style="border: 0;background: azure;" (click)="dataDownload('1')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="1">Instantaneous Profile Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading2">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message2.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message2}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message2}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Activate_Emergency_Credit_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx20" style="border: 0;background: azure;" (click)="dataDownload('26')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading20, 'fa-download': !isLoading20}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading20" value="26">Activate Emergeny Credit</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading20">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message20.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message20}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message20}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Billing_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx1" style="border: 0;background: azure;" (click)="dataDownload('5')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="5">Billing Profile Data Download (All/Pending)</mat-radio-button>

            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading3">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message3.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message3}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message3}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Billing_Button">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select [disabled]="isLoadingx1" placeholder="Billing" name="offSetBilling"
                      formControlName="offSetBilling" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aD.offSetBilling.errors }" required>
                      <mat-option value="0">Download Current Billing</mat-option>
                      <mat-option value="1">Download Billing 1</mat-option>
                      <mat-option value="2">Download Billing 2</mat-option>
                      <mat-option value="3">Download Billing 3</mat-option>
                      <mat-option value="4">Download Billing 4</mat-option>
                      <mat-option value="5">Download Billing 5</mat-option>
                      <mat-option value="6">Download Billing 6</mat-option>
                      <mat-option value="7">Download Billing 7</mat-option>
                      <mat-option value="8">Download Billing 8</mat-option>
                      <mat-option value="9">Download Billing 9</mat-option>
                      <mat-option value="10">Download Billing 10</mat-option>
                      <mat-option value="11">Download Billing 11</mat-option>
                      <mat-option value="12">Download Billing 12</mat-option>

                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label> Billing</label>
                    <div *ngIf="submitted && aD.offSetBilling.errors" class="invalid-feedback">
                      <div *ngIf="aD.offSetBilling.errors.required">
                        Offset Is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading3">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message3.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message3}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message3}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Block_Load_Survey_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx2" style="border: 0;background: azure;" (click)="dataDownload('19')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <div>
              <mat-radio-button [disabled]="isLoading" value="19">Block Load Survey Data</mat-radio-button>
            </div>
            <div>
              <div class="form-group input-group">
                <span class="has-float-label">
                  <input class="form-control" [maxDate]="today" bsDaterangepicker placeholder="Choose a date"
                    (bsValueChange)="blockLoadSurveyDateRange($event)" [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY' }">
                  <i class="fa fa-calendar icon_innertxtbox"></i>

                  <label>Choose a date</label>
                </span>
              </div>
            </div>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading4">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message4.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message4}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message4}}
                  </div>
                </div>
              </div>
            </div>

          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Daily_Load_Survey_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx3" style="border: 0;background: azure;" (click)="dataDownload('20')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <div>
              <mat-radio-button [disabled]="isLoading" value="20">Daily Load Survey Data Download</mat-radio-button>
            </div>
            <div>
              <div class="form-group input-group">
                <span class="has-float-label">
                  <input class="form-control" [maxDate]="today" bsDaterangepicker placeholder="Choose a date"
                    (bsValueChange)="dailyLoadSurveyDateRange($event)" [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY' }">
                  <i class="fa fa-calendar icon_innertxtbox"></i>

                  <label>Choose a date</label>
                </span>
              </div>
            </div>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading5">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message5.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message5}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message5}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx6" style="border: 0;background: azure;" (click)="dataDownload('25')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="25">Full Events Data Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading6">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message6.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message6}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message6}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx28" style="border: 0;background: azure;" (click)="dataDownload('28')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="28">Control Event Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading28">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message28.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message28}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message28}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx29" style="border: 0;background: azure;" (click)="dataDownload('29')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="29">Current Event Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading29">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message29.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message29}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message29}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx30" style="border: 0;background: azure;" (click)="dataDownload('30')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="30">Non-Rollover Event Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading30">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message30.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message30}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message30}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx31" style="border: 0;background: azure;" (click)="dataDownload('31')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="31">Other Event Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading31">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message31.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message31}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message31}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx32" style="border: 0;background: azure;" (click)="dataDownload('32')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="32">Power Event Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading32">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message32.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message32}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message32}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx33" style="border: 0;background: azure;" (click)="dataDownload('33')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="33">Transactional Event Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading33">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message33.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message33}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message33}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx34" style="border: 0;background: azure;" (click)="dataDownload('34')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="34">Voltage Event Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading34">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message34.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message34}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message34}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Tamper_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx27" style="border: 0;background: azure;" (click)="dataDownload('27')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading27" value="27">Nameplate Data Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading27">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message27.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message27}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message27}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Voltage_Event_Button || getThrough.authority.Meter_Sub_Meter_Download_Selective_Current_Event_Button">
            <legend class="scheduler-border">
              <img class="blink-image" src="" style="width: 25px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Voltage_Event_Button">
              <mat-radio-button [disabled]="isLoading" value="12"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Voltage_Event_Button"
                style="margin-bottom:30px;margin-top:15px;">VOLTAGE RELATED EVENTS</mat-radio-button>
              <img class="blink-image" src="" style="width: 25px;margin-left: 50px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Current_Event_Button">
              <mat-radio-button [disabled]="isLoading" value="13"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Current_Event_Button">
                CURRENT RELATED EVENTS
              </mat-radio-button>
            </legend>
            <div class="row">
              <div class="col-sm-9"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Voltage_Event_Button">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select [disabled]="isLoadingx7" placeholder="Event Range" name="offSetVoltage"
                      formControlName="offSetVoltage" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aF.offSetVoltage.errors }" required>
                      <mat-option value="0">0 - 10 Events</mat-option>
                      <mat-option value="10">11 - 20 Events</mat-option>
                      <mat-option value="20">21 - 30 Events</mat-option>
                      <mat-option value="30">31 - 40 Events</mat-option>
                      <mat-option value="40">41 - 50 Events</mat-option>
                      <mat-option value="50">51 - 60 Events</mat-option>
                      <mat-option value="60">61 - 70 Events</mat-option>
                      <mat-option value="70">71 - 80 Events</mat-option>
                      <mat-option value="80">81 - 90 Events</mat-option>
                      <mat-option value="90">91 - 98 Events</mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label> Event Range</label>
                    <div *ngIf="submitted && aD.offSetVoltage.errors" class="invalid-feedback">
                      <div *ngIf="aD.offSetVoltage.errors.required">
                        Offset Is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-sm-3" style="padding: 12px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Voltage_Event_Button">
                <button [disabled]="isLoadingx7" style="border: 0;background: azure;" (click)="dataDownload('12')">
                  <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                    style="font-size: 20px;"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Current_Event_Button">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select [disabled]="isLoadingx8" placeholder="Event Range" name="limitCurrent"
                      formControlName="limitCurrent" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aD.limitCurrent.errors }" required>
                      <mat-option value="0">0 - 10 Events</mat-option>
                      <mat-option value="10">11 - 20 Events</mat-option>
                      <mat-option value="20">21 - 30 Events</mat-option>
                      <mat-option value="30">31 - 40 Events</mat-option>
                      <mat-option value="40">41 - 50 Events</mat-option>
                      <mat-option value="50">51 - 60 Events</mat-option>
                      <mat-option value="60">61 - 70 Events</mat-option>
                      <mat-option value="70">71 - 80 Events</mat-option>
                      <mat-option value="80">81 - 90 Events</mat-option>
                      <mat-option value="90">91 - 100 Events</mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Event Range</label>
                    <div *ngIf="submitted && aD.limitCurrent.errors" class="invalid-feedback">
                      <div *ngIf="aD.limitCurrent.errors.required">
                        Offset Is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-sm-3" style="padding: 12px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Current_Event_Button">
                <button [disabled]="isLoadingx8" style="border: 0;background: azure;" (click)="dataDownload('13')">
                  <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                    style="font-size: 20px;"></i>
                </button>
              </div>
            </div>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading7">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message7.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message7}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message7}}
                  </div>
                </div>
                <ng-container *ngIf="isLoading8">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message8.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message8}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message8}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Power_Event_Button || getThrough.authority.Meter_Sub_Meter_Download_Selective_Transact_Event_Button">
            <legend class="scheduler-border">
              <img class="blink-image" src="" style="width: 25px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Power_Event_Button">
              <mat-radio-button [disabled]="isLoading" value="14"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Power_Event_Button"
                style="margin-bottom:30px;margin-top:15px;">POWER RELATED EVENTS</mat-radio-button>
              <img class="blink-image" src="" style="width: 25px;margin-left: 65px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Transact_Event_Button">
              <mat-radio-button [disabled]="isLoading" value="15"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Transact_Event_Button">
                TRANSACTION RELATED EVENTS</mat-radio-button>
            </legend>
            <div class="row">
              <div class="col-sm-9" *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Power_Event_Button">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select [disabled]="isLoadingx9" placeholder="Event Range" name="offSetPower"
                      formControlName="offSetPower" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aD.offSetPower.errors }" required>
                      <mat-option value="0">0 - 10 Events</mat-option>
                      <mat-option value="10">11 - 20 Events</mat-option>
                      <mat-option value="20">21 - 30 Events</mat-option>
                      <mat-option value="30">31 - 40 Events</mat-option>
                      <mat-option value="40">41 - 50 Events</mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Event Range</label>
                    <div *ngIf="submitted && aD.offSetPower.errors" class="invalid-feedback">
                      <div *ngIf="aD.offSetPower.errors.required">
                        Offset Is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-sm-3" style="padding: 12px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Power_Event_Button">
                <button [disabled]="isLoadingx9" style="border: 0;background: azure;" (click)="dataDownload('14')">
                  <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                    style="font-size: 20px;"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Transact_Event_Button">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select [disabled]="isLoadingx10" placeholder="Event Range" name="offSetTransactional"
                      formControlName="offSetTransactional" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aD.offSetTransactional.errors }" required>
                      <mat-option value="0">0 TO 10 Events</mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Event Range</label>
                    <div *ngIf="submitted && aD.offSetTransactional.errors" class="invalid-feedback">
                      <div *ngIf="aD.offSetTransactional.errors.required">
                        Offset Is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-sm-3" style="padding: 12px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Transact_Event_Button">
                <button [disabled]="isLoadingx10" style="border: 0;background: azure;" (click)="dataDownload('15')">
                  <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                    style="font-size: 20px;"></i>
                </button>
              </div>
            </div>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading9">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message9.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message9}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message9}}
                  </div>
                </div>
                <ng-container *ngIf="isLoading10">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message10.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message10}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message10}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Other_Event_Button || getThrough.authority.Meter_Sub_Meter_Download_All_Cover_Open_Event_Button">
            <legend class="scheduler-border">
              <img class="blink-image" src="" style="width: 25px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Other_Event_Button">
              <mat-radio-button [disabled]="isLoading" value="16"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Other_Event_Button"
                style="margin-top: 20px;margin-bottom:20px;">OTHER EVENTS</mat-radio-button>
              <img class="blink-image" src="" style="width: 25px;margin-left: 150px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Cover_Open_Event_Button">
              <mat-radio-button [disabled]="isLoading" value="17"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Cover_Open_Event_Button" style="">COVER OPEN
                EVENT</mat-radio-button>
            </legend>
            <div class="row">
              <div class="col-sm-9" *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Other_Event_Button">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select [disabled]="isLoadingx11" placeholder="Event Range" name="offSetOther"
                      formControlName="offSetOther" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aD.offSetOther.errors }" required>
                      <mat-option value="0">0 - 10 Events</mat-option>
                      <mat-option value="10">11 - 20 Events</mat-option>
                      <mat-option value="20">21 - 30 Events</mat-option>
                      <mat-option value="30">31 - 40 Events</mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Event Range</label>
                    <div *ngIf="submitted && aD.offSetOther.errors" class="invalid-feedback">
                      <div *ngIf="aD.offSetOther.errors.required">
                        Offset Is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-sm-3" style="padding: 12px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Other_Event_Button">
                <button [disabled]="isLoadingx11" style="border: 0;background: azure;" (click)="dataDownload('16')">
                  <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                    style="font-size: 20px;"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9" *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Cover_Open_Event_Button">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select [disabled]="isLoadingx12" placeholder="Event Range" name="offSetCoverOpen"
                      formControlName="offSetCoverOpen" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aD.offSetCoverOpen.errors }" required>
                      <mat-option value="0">0 - 1 Event</mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Event Range</label>
                    <div *ngIf="submitted && aD.offSetCoverOpen.errors" class="invalid-feedback">
                      <div *ngIf="aD.offSetCoverOpen.errors.required">
                        Offset Is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-sm-3" style="padding: 12px;"
                *ngIf="getThrough.authority.Meter_Sub_Meter_Download_All_Cover_Open_Event_Button">
                <button [disabled]="isLoadingx12" style="border: 0;background: azure;" (click)="dataDownload('17')">
                  <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                    style="font-size: 20px;"></i>
                </button>
              </div>
            </div>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading11">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message11.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message11}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message11}}
                  </div>
                </div>
                <ng-container *ngIf="isLoading12">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message12.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message12}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message12}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Selective_Control_Event_Button">
            <legend class="scheduler-border">
              <img class="blink-image" src="" style="width: 25px;">
              <mat-radio-button [disabled]="isLoading" value="18">CONTROL EVENTS</mat-radio-button>
            </legend>
            <div class="row">
              <div class="col-sm-9">
                <div class="form-group input-group">
                  <span class="has-float-label">
                    <mat-select [disabled]="isLoadingx13" placeholder="Event Range" name="offCurrentEvent"
                      formControlName="offCurrentEvent" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && aD.offCurrentEvent.errors }" required>
                      <mat-option value="0">0 - 10 Events</mat-option>
                      <mat-option value="10">11 - 20 Events</mat-option>
                    </mat-select>
                    <i class="fa fa-database icon_innertxtbox"></i>
                    <label>Event Range</label>
                    <div *ngIf="submitted && aD.offCurrentEvent.errors" class="invalid-feedback">
                      <div *ngIf="aD.offCurrentEvent.errors.required">
                        Offset Is required
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-sm-3">
                <button [disabled]="isLoadingx13" style="border: 0;background: azure;" (click)="dataDownload('18')">
                  <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                    style="font-size: 20px;"></i>
                </button>
              </div>
            </div>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading13">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message13.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message13}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message13}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <!--<fieldset class="scheduler-border" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View">
                        <legend class="scheduler-border">
                          <img class="blink-image" src="assets/images/tamper.png" style="width: 25px;">
                          <mat-radio-button [disabled]="isLoading" value="13">CURRENT RELATED EVENTS</mat-radio-button>
                        </legend>
                        <div class="col-sm-12 row">
                          <div class="col-sm-2" style="padding: 12px;">
                            Offset
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #offsetcurrent matInput placeholder="Offset" name="offSetCurrent" 
                              formControlName="offSetCurrent"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.offSetCurrent.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            Limit
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #limitcurrent matInput placeholder="Limit" name="limitCurrent" 
                              formControlName="limitCurrent"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.limitCurrent.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            <button [disabled]="isLoadingx8" style="border: 0;background: azure;" (click)="dataDownload('13')">
                              <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}" style="font-size: 20px;"></i>
                            </button>
                          </div>
                        </div>  
                        <div class="col-sm-12 row">
                          <div class="col-sm-12 text-left">
                              <ng-container *ngIf="isLoading8">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                              </ng-container>
                              <div role="alert" *ngIf="message8.length > 0">
                                <div class="alert alert-success " role="alert" *ngIf="thumb">
                                  <mat-icon>thumb_up</mat-icon>&nbsp;{{message8}}
                                </div>
                                <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                  <mat-icon>thumb_down</mat-icon>&nbsp;{{message8}}
                                </div>
                              </div>
                              <ng-container *ngIf="isLoading9">
                                <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                              </ng-container>
                                <div role="alert" *ngIf="message9.length > 0">
                                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message9}}
                                  </div>
                                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message9}}
                                  </div>
                                </div>
                            </div>
                        </div>   
                      </fieldset>

                      <fieldset class="scheduler-border" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View">
                        <legend class="scheduler-border">
                          <img class="blink-image" src="assets/images/tamper.png" style="width: 25px;">
                          <mat-radio-button [disabled]="isLoading" value="14">POWER RELATED EVENTS</mat-radio-button>
                        </legend>
                        <div class="col-sm-12 row">
                          <div class="col-sm-2" style="padding: 12px;">
                            Offset
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #offsetpower matInput placeholder="Offset" name="offSetPower" 
                              formControlName="offSetPower"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.offSetPower.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            Limit
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #limitpower matInput placeholder="Limit" name="limitPower" 
                              formControlName="limitPower"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.limitPower.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            <button [disabled]="isLoadingx9" style="border: 0;background: azure;" (click)="dataDownload('14')">
                              <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}" style="font-size: 20px;"></i>
                            </button>
                          </div>
                        </div>  
                        <div class="col-sm-12 row">
                          <div class="col-sm-12 text-left">
                            <ng-container *ngIf="isLoading9">
                              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                            </ng-container>
                              <div role="alert" *ngIf="message9.length > 0">
                                <div class="alert alert-success " role="alert" *ngIf="thumb">
                                  <mat-icon>thumb_up</mat-icon>&nbsp;{{message9}}
                                </div>
                                <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                  <mat-icon>thumb_down</mat-icon>&nbsp;{{message9}}
                                </div>
                              </div>
                            </div>
                        </div>   
                      </fieldset>

                      <fieldset class="scheduler-border" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View">
                        <legend class="scheduler-border">
                          <img class="blink-image" src="assets/images/tamper.png" style="width: 25px;">
                          <mat-radio-button [disabled]="isLoading" value="15">TRANSACTION RELATED EVENTS</mat-radio-button>
                        </legend>
                        <div class="col-sm-12 row">
                          <div class="col-sm-2" style="padding: 12px;">
                            Offset
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #offsettransaction matInput placeholder="Offset" name="offSetTransactional" 
                              formControlName="offSetTransactional"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.offSetTransactional.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            Limit
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #limittransaction matInput placeholder="Limit" name="limitTransactional" 
                              formControlName="limitTransactional"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.limitTransactional.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            <button [disabled]="isLoadingx10" style="border: 0;background: azure;" (click)="dataDownload('15')">
                              <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}" style="font-size: 20px;"></i>
                            </button>
                          </div>
                        </div> 
                        <div class="col-sm-12 row">
                          <div class="col-sm-12 text-left">
                            <ng-container *ngIf="isLoading10">
                              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                            </ng-container>
                              <div role="alert" *ngIf="message10.length > 0">
                                <div class="alert alert-success " role="alert" *ngIf="thumb">
                                  <mat-icon>thumb_up</mat-icon>&nbsp;{{message10}}
                                </div>
                                <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                  <mat-icon>thumb_down</mat-icon>&nbsp;{{message10}}
                                </div>
                              </div>
                            </div>
                        </div>    
                      </fieldset>

                      <fieldset class="scheduler-border" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View">
                        <legend class="scheduler-border">
                          <img class="blink-image" src="assets/images/tamper.png" style="width: 25px;">
                          
                          <mat-radio-button [disabled]="isLoading" value="16">OTHER EVENTS</mat-radio-button>
                        </legend>
                        <div class="col-sm-12 row">
                          <div class="col-sm-2" style="padding: 12px;">
                            Offset
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #offsetother matInput placeholder="Offset" name="offSetOther" 
                              formControlName="offSetOther"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.offSetOther.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            Limit
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #limitother matInput placeholder="Limit" name="limitOther" 
                              formControlName="limitOther"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.limitOther.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            <button [disabled]="isLoadingx11" style="border: 0;background: azure;" (click)="dataDownload('16')">
                              <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}" style="font-size: 20px;"></i>
                            </button>
                          </div>
                        </div>  
                        <div class="col-sm-12 row">
                          <div class="col-sm-12 text-left">
                            <ng-container *ngIf="isLoading11">
                              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                            </ng-container>
                              <div role="alert" *ngIf="message11.length > 0">
                                <div class="alert alert-success " role="alert" *ngIf="thumb">
                                  <mat-icon>thumb_up</mat-icon>&nbsp;{{message11}}
                                </div>
                                <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                  <mat-icon>thumb_down</mat-icon>&nbsp;{{message11}}
                                </div>
                              </div>
                            </div>
                        </div>   
                      </fieldset>

                      <fieldset class="scheduler-border" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View">
                        <legend class="scheduler-border">
                          <img class="blink-image" src="assets/images/tamper.png" style="width: 25px;">
                          <mat-radio-button [disabled]="isLoading" value="17">COVER OPEN EVENT</mat-radio-button>
                        </legend>
                        <div class="col-sm-12 row">
                          <div class="col-sm-2" style="padding: 12px;">
                            Offset
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #offsetcoveropen matInput placeholder="Offset" name="offSetCoverOpen" 
                              formControlName="offSetCoverOpen"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.offSetCoverOpen.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            Limit
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #limitcoveropen matInput placeholder="Limit" name="limitCoverOpen" 
                              formControlName="limitCoverOpen"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.limitCoverOpen.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            <button [disabled]="isLoadingx12" style="border: 0;background: azure;" (click)="dataDownload('17')">
                              <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}" style="font-size: 20px;"></i>
                            </button>
                          </div>
                        </div>  
                        <div class="col-sm-12 row">
                          <div class="col-sm-12 text-left">
                            <ng-container *ngIf="isLoading12">
                              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                            </ng-container>
                              <div role="alert" *ngIf="message12.length > 0">
                                <div class="alert alert-success " role="alert" *ngIf="thumb">
                                  <mat-icon>thumb_up</mat-icon>&nbsp;{{message12}}
                                </div>
                                <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                  <mat-icon>thumb_down</mat-icon>&nbsp;{{message12}}
                                </div>
                              </div>
                            </div>
                        </div>   
                      </fieldset>

                      <fieldset class="scheduler-border" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Utility_View">
                        <legend class="scheduler-border">
                          <img class="blink-image" src="assets/images/tamper.png" style="width: 25px;">
                          <mat-radio-button [disabled]="isLoading" value="18">CONTROL EVENTS</mat-radio-button>
                        </legend>
                        <div class="col-sm-12 row">
                          <div class="col-sm-2" style="padding: 12px;">
                            Offset
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #offsetcontrolevent matInput placeholder="Offset" name="offCurrentEvent" 
                              formControlName="offCurrentEvent"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.offCurrentEvent.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            Limit
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field  style="margin-top: -8px;">
                              <input #limitcontrolevent matInput placeholder="Limit" name="limitCurrentEvent" 
                              formControlName="limitCurrentEvent"  autocomplete="off">
                              <mat-icon matPrefix class="d-c11">settings_cell</mat-icon>
                              <mat-error *ngIf="aD.limitCurrentEvent.hasError('required')">
                              Offset Is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2" style="padding: 12px;">
                            <button [disabled]="isLoadingx13" style="border: 0;background: azure;" (click)="dataDownload('18')">
                              <i class="fa d-c4" [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}" style="font-size: 20px;"></i>
                            </button>
                          </div>
                        </div>  
                        <div class="col-sm-12 row">
                          <div class="col-sm-12 text-left">
                            <ng-container *ngIf="isLoading13">
                              <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                            </ng-container>
                              <div role="alert" *ngIf="message13.length > 0">
                                <div class="alert alert-success " role="alert" *ngIf="thumb">
                                  <mat-icon>thumb_up</mat-icon>&nbsp;{{message13}}
                                </div>
                                <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                                  <mat-icon>thumb_down</mat-icon>&nbsp;{{message13}}
                                </div>
                              </div>
                            </div>
                        </div>   
                      </fieldset>-->

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Load_Limit_Config_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx15" style="border: 0;background: azure;" (click)="dataDownload('21')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="21">Load Limit Configuration <br> Data
              Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading15">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message15.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message15}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message15}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Happy_Hours_Config_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoadingx16" style="border: 0;background: azure;" (click)="dataDownload('22')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="22">Get Happy Hours Configuration <br> Data
              Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading16">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message16.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message16}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message16}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border subdonloadingroprt"
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Alarm_Level_Config_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoading17" style="border: 0;background: azure;" (click)="dataDownload('23')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="23">
              Get Alarm Level/Emergeny Credit <br>/Billing Tariff Configuration <br>Data Download
            </mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading17">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message17.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message17}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message17}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border "
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Billing_Date_Config_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoading18" style="border: 0;background: azure;" (click)="dataDownload('24')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="24">Get Billing Date Configuration <br>Data
              Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading18">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message18.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message18}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message18}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="scheduler-border "
            *ngIf="getThrough.authority.Meter_Sub_Meter_Download_Billing_Date_Config_Button">
            <legend class="scheduler-border">
              <button [disabled]="isLoading19" style="border: 0;background: azure;" (click)="dataDownload('68')">
                <i class="fa d-c4 roportingdownloadFa"
                  [ngClass]="{'fa-spin fa-download': isLoading1, 'fa-download': !isLoading1}"
                  style="font-size: 20px;"></i>
              </button>
            </legend>
            <mat-radio-button [disabled]="isLoading" value="68">Get Relay Configuration <br>Data
              Download</mat-radio-button>
            <div class="col-sm-12 row">
              <div class="col-sm-12 text-left">
                <ng-container *ngIf="isLoading19">
                  <fa-icon [icon]="faSpinner" [spin]="true" size="4x" class="makeCenter"></fa-icon>
                </ng-container>
                <div role="alert" *ngIf="message19.length > 0">
                  <div class="alert alert-success " role="alert" *ngIf="thumb">
                    <mat-icon>thumb_up</mat-icon>&nbsp;{{message19}}
                  </div>
                  <div class="alert alert-secondary " role="alert" *ngIf="!thumb">
                    <mat-icon>thumb_down</mat-icon>&nbsp;{{message19}}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <!--<div  class="col-sm-12" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                        <mat-icon class="d-c10">assignment</mat-icon><mat-radio-button value="21">Get Load Limit Configuration Data</mat-radio-button>
                      </div>
                      <div  class="col-sm-12" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                        <mat-icon class="d-c10">assignment</mat-icon><mat-radio-button value="22">Get Happy Hours Configuration Data</mat-radio-button>
                      </div>
                      <div  class="col-sm-12" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                        <mat-icon class="d-c10">assignment</mat-icon><mat-radio-button value="23" >Get Alarm Level/Emergeny Credit /Billing Tariff Configuration Data</mat-radio-button>
                      </div>
                      <div  class="col-sm-12" *ngIf="getThrough.authority.Auth_All_Griphical_Desh_Grid_Society_View">
                        <mat-icon class="d-c10">assignment</mat-icon><mat-radio-button value="24">Get Billing Date Configuration Data</mat-radio-button>
                      </div>-->

        </mat-radio-group>
        <!--<button [disabled]="AddProcess" type="submit" class="btn btn-primary btn-block" >
                        Report
                    </button>-->


      </form>
    </div>

  </ng-container>
</div>