import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Access } from 'src/app/_models/access';
import { IfStmt } from '@angular/compiler';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-meter-reporting',
  templateUrl: './meter-reporting.component.html',
  styleUrls: ['./meter-reporting.component.scss']
})


export class MeterReportingComponent implements OnInit {
 


  faSpinner = faSpinner;
  public today = new Date();
  currentUser: User;
  currentUserSubscription: Subscription;
  loading:boolean = false;
  reportForm: UntypedFormGroup;
  responseMessage:string = '';
  EditName:string = '';
  accesLevel = 0;
  AddProcess:boolean = false;
  selectedSubDivision:any = 0;
  meterDetail:any = {};
  message:string = '';
  message1:string = '';
  message2:string = '';
  message3:string = '';
  message4:string = '';
  message5:string = '';
  message6:string = '';
  message7:string = '';
  message8:string = '';
  message9:string = '';
  message10:string = '';
  message11:string = '';
  message12:string = '';
  message13:string = '';
  message14:string = '';

  message15:string = '';
  message16:string = '';
  message17:string = '';
  message18:string = '';
  message19:string = '';
  message20:string = '';
  message27:string = '';

  message28:string = '';
  message29:string = '';
  message30:string = '';
  message31:string = '';
  message32:string = '';
  message33:string = '';
  message34:string = '';

  responseData: any;
  
  isLoading = false;
  isLoading1 = false;
  isLoading2 = false;
  isLoading3 = false;
  isLoading4 = false;
  isLoading5 = false;
  isLoading6 = false;
  isLoading7 = false;
  isLoading8 = false;
  isLoading9 = false;
  isLoading10 = false;
  isLoading11 = false;
  isLoading12 = false;
  isLoading13 = false;
  isLoading14 = false;
  isLoading15 = false;
  isLoading16 = false;
  isLoading17 = false;
  isLoading18 = false;
  isLoading19 = false;
  isLoading20 = false;
  isLoading27 = false;

  isLoading28 = false;
  isLoading29 = false;
  isLoading30 = false;
  isLoading31 = false;
  isLoading32 = false;
  isLoading33 = false;
  isLoading34 = false;



  isLoadingx = true;
  isLoadingx1 = true;
  isLoadingx2 = true;
  isLoadingx3 = true;
  isLoadingx6 = true;
  isLoadingx27 = true;
  isLoadingx7 = true;
  isLoadingx8 = true;
  isLoadingx9 = true;
  isLoadingx10 = true;
  isLoadingx11 = true;
  isLoadingx12 = true;
  isLoadingx13 = true;
  isLoadingx14 = true;
  isLoadingx15 = true;
  isLoadingx16 = true;
  isLoadingx17 = true;
  isLoadingx18 = true;
  isLoadingx19 = true;
  isLoadingx20 = true;

  isLoadingx28 = true;
  isLoadingx29 = true;
  isLoadingx30 = true;
  isLoadingx31 = true;
  isLoadingx32 = true;
  isLoadingx33 = true;
  isLoadingx34 = true;

  submitted: boolean = false;
  thumb:boolean = false;
  getThrough: Access;
  startDate = '';
  endDate = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private genReqs:generalRequestsService,
    private authenticationService: AuthenticationService,
    public modal: NgbActiveModal,
    private ngmodalService: NgbModal,
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  ngOnInit() {
    if(this.meterDetail.srNo){
    console.log(this.meterDetail);
    }
    this.reportForm = this.formBuilder.group({
      reporting: [''],
      offSetVoltage: { value: '0', disabled: this.isLoading},
      limitVoltage: { value: '10', disabled: false},
      limiAllLoadSurveye: { value: '', disabled: this.isLoading},
      offSetAllLoadSurvey: { value: '', disabled: this.isLoading},
      limitBlockLoadSurvey: { value: '10', disabled: false},
      offSetBlockLoadSurvey: { value: '0', disabled: this.isLoading},
      limitCurrentEvent: { value: '10', disabled: false},
      offCurrentEvent: { value: '0', disabled: this.isLoading},
      limitCoverOpen: { value: '10', disabled: false},
      offSetCoverOpen: { value: '0', disabled: this.isLoading},
      limitOther: { value: '10', disabled: false},
      offSetOther: { value: '0', disabled: this.isLoading},
      limitTransactional: { value: '10', disabled: false},
      offSetTransactional: { value: '0', disabled: this.isLoading},
      limitPower: { value: '10', disabled: false},
      offSetPower: { value: '0', disabled: this.isLoading},
      limitCurrent: { value: '0', disabled: false},
      offSetCurrent: { value: '0', disabled: this.isLoading},
      offSetBilling: { value: '0', disabled: this.isLoading},
      //limitBilling: { value: '1', disabled: false},
      OffsetFullTamper: { value: '', disabled: this.isLoading},
      limitFullTamper: { value: '', disabled: this.isLoading},
    });
    //this.aD.reporting.setValue('1');
  }
  ngOnDestroy() {
    //this.ngmodalService.dismissAll();
}

dailyLoadSurveyDateRange(pickedRange: Date[]) {

  if (!pickedRange || pickedRange.length === 0) {
    this.startDate = null;
    this.endDate = null;
    return;
  }
  ``
  let smonth = pickedRange[0].getMonth() + 1; //always move 1 value up
  let sday = pickedRange[0].getDate();
  let syear = pickedRange[0].getFullYear();

  if(smonth.toString().length==1 && sday.toString().length==1){
    this.startDate = syear + '-0' + smonth + '-0' + sday;
  }else if(smonth.toString().length==1 && sday.toString().length!=1){
    this.startDate = syear + '-0' + smonth + '-' + sday;
  }else if(smonth.toString().length!=1 && sday.toString().length==1){
    this.startDate = syear + '-' + smonth + '-0' + sday;
  }else{
    this.startDate = syear + '-' + smonth + '-' + sday;
  }

 
  let emonth = pickedRange[1].getMonth() + 1; //always move 1 value up
  let eday = pickedRange[1].getDate();
  let eyear = pickedRange[1].getFullYear();
  
  if(emonth.toString().length==1 && eday.toString().length==1){
    this.endDate = eyear + '-0' + emonth + '-0' + eday;
  }else if(emonth.toString().length==1 && eday.toString().length!=1){
    this.endDate = eyear + '-0' + emonth + '-' + eday;
  }else if(emonth.toString().length!=1 && eday.toString().length==1){
    this.endDate = eyear + '-' + emonth + '-0' + eday;
  }else{
    this.endDate = eyear + '-' + emonth + '-' + eday;
  }
}

blockLoadSurveyDateRange(pickedRange: Date[]) {
  
  if (!pickedRange || pickedRange.length === 0) {
    this.startDate = null;
    this.endDate = null;
    return;
  }

  let smonth = pickedRange[0].getMonth() + 1; //always move 1 value up
  let sday = pickedRange[0].getDate();
  let syear = pickedRange[0].getFullYear();

  if(smonth.toString().length==1 && sday.toString().length==1){
    this.startDate = syear + '-0' + smonth + '-0' + sday;
  }else if(smonth.toString().length==1 && sday.toString().length!=1){
    this.startDate = syear + '-0' + smonth + '-' + sday;
  }else if(smonth.toString().length!=1 && sday.toString().length==1){
    this.startDate = syear + '-' + smonth + '-0' + sday;
  }else{
    this.startDate = syear + '-' + smonth + '-' + sday;
  }

 
  let emonth = pickedRange[1].getMonth() + 1; //always move 1 value up
  let eday = pickedRange[1].getDate();
  let eyear = pickedRange[1].getFullYear();
  
  if(emonth.toString().length==1 && eday.toString().length==1){
    this.endDate = eyear + '-0' + emonth + '-0' + eday;
  }else if(emonth.toString().length==1 && eday.toString().length!=1){
    this.endDate = eyear + '-0' + emonth + '-' + eday;
  }else if(emonth.toString().length!=1 && eday.toString().length==1){
    this.endDate = eyear + '-' + emonth + '-0' + eday;
  }else{
    this.endDate = eyear + '-' + emonth + '-' + eday;
  }
}

public formControlIsValid(formGroup, formCtrl) : boolean {
  if (formGroup.controls.hasOwnProperty(formCtrl)) {
    if (!formGroup.controls[formCtrl].touched) return true;
    return formGroup.controls[formCtrl].valid;
  }
  return false;
}

//get rF() { return this.reportForm.controls; }
get aD() { return this.reportForm.controls; }

onSubmitAddForm(){
  if (this.aD.reporting.value) {
    if (this.reportForm.invalid)
     {
      this.submitted = true;
      return;
    }
    this.message ='';
    this.AddProcess = true;
    this.isLoading = true;
    //console.log(this.meterDetail);
    let body = new URLSearchParams();
    body.set('subdivId',this.currentUser.data.userData.subdivision);
    body.set('dcuId', this.currentUser.data.userData.dcu);
    body.set('meterType', this.meterDetail.meterType);
    body.set('conAccId', this.meterDetail.cACNO);
    body.set('meterId', this.meterDetail.muID);
    body.set('reporting', this.aD.reporting.value);
    if(this.meterDetail.srNo){
      body.set('cmdFrom', '1');
    }else{
      body.set('cmdFrom', '0');
    }
    if(this.currentUser.data.userData.subdivision=='109'){
      body.set('commMode', '1');
    }else{
      body.set('commMode', this.meterDetail.commMode);
    }
    body.set('dgFreeUnits', this.meterDetail.dgFreeUnits);
    body.set('authToken', this.currentUser.authToken);
    body.set('sdate', this.startDate.toString());
    body.set('edate', this.endDate.toString());
    
    this.genReqs.postReq('/addMeterReportingDesh', body).subscribe((result)=>{
      console.log("--------------"+this.message);
      this.responseData = result;
        this.AddProcess = false;
        this.isLoading = false;
        if (this.responseData.success) {
          this.thumb = true;
          this.message = this.responseData.data.message;
          console.log("--------------"+this.message);
          if(this.message.includes("Not") || this.message.includes("not updated") || this.message.includes("You are not authorize") || this.message.includes("Communication Failure") || this.message.includes("Un-Successfully")|| this.message.includes("Unsuccessfully") || this.message.includes("Error")){
            this.thumb = false;
          }else{
            this.thumb = true;
          }
          //this.modal.close('done');
        }
    },(err)=>{
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.thumb = false;
      this.message = 'Unable to process yours request!';
    });
  }else{
  alert("Please select at least one option to proceed further!");
  }
}

onItemChange(event: MatRadioChange){
 //alert(event.value);
  if(event.value==1){
    this.isLoadingx = false;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3 = true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }

  if(event.value==5){
    this.isLoadingx = true;
    this.isLoadingx1= false;
    this.isLoadingx2 = true;
    this.isLoadingx3 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==19){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2= false;
    this.isLoadingx3 = true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==20){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= false;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==25){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = false;
    this.isLoadingx27 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }

  if(event.value==28){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    
    this.isLoadingx28 = false;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;

    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }

  if(event.value==29){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    
    this.isLoadingx28 = true;
    this.isLoadingx29 = false;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;

    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }

  if(event.value==30){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = false;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;

    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==31){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = false;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;

    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  
  if(event.value==32){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = false;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;

    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==33){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = false;
    this.isLoadingx34 = true;

    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==34){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = false;

    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  
  
  if(event.value==12){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;

    this.isLoadingx7 = false;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==13){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = false;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==14){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = false;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==15){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = false;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==16){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = false;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==17){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = false;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==18){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = false;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==21){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = false;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==22){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = false;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==23){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = false;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  if(event.value==24){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = false;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }

  if(event.value==25){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = false;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = false;
    this.isLoadingx20 = true;
  }
  if(event.value==26){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = true;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = false;
  }
  if(event.value==27){
    this.isLoadingx = true;
    this.isLoadingx1 = true;
    this.isLoadingx2 = true;
    this.isLoadingx3= true;
    this.isLoadingx6 = true;
    this.isLoadingx27 = false;
    this.isLoadingx28 = true;
    this.isLoadingx29 = true;
    this.isLoadingx30 = true;
    this.isLoadingx31 = true;
    this.isLoadingx32 = true;
    this.isLoadingx33 = true;
    this.isLoadingx34 = true;
    this.isLoadingx7 = true;
    this.isLoadingx8 = true;
    this.isLoadingx9 = true;
    this.isLoadingx10 = true;
    this.isLoadingx11 = true;
    this.isLoadingx12 = true;
    this.isLoadingx13 = true;
    this.isLoadingx15 = true;
    this.isLoadingx16 = true;
    this.isLoadingx17 = true;
    this.isLoadingx18 = true;
    this.isLoadingx19 = true;
    this.isLoadingx20 = true;
  }
  
}

checkValue(){
  //alert("hi");
  if(this.aD.reporting.value=='1'){
    this.isLoading=true;
  }
  //alert(this.aD.reporting.value);
}

@ViewChild('offsetbilling') offsetbilling: ElementRef;
//@ViewChild('limitbilling') limitbilling: ElementRef;
@ViewChild('limitallloadsurvey') limitallloadsurvey: ElementRef;
@ViewChild('offsetallloadsurvey') offsetallloadsurvey: ElementRef;
@ViewChild('limitblockload') limitblockload: ElementRef;
@ViewChild('offsetblockload') offsetblockload: ElementRef;
@ViewChild('limitcontrolevent') limitcontrolevent: ElementRef;
@ViewChild('offsetcontrolevent') offsetcontrolevent: ElementRef;
@ViewChild('limitcoveropen') limitcoveropen: ElementRef;
@ViewChild('offsetcoveropen') offsetcoveropen: ElementRef;
@ViewChild('limitother') limitother: ElementRef;
@ViewChild('offsetother') offsetother: ElementRef;
@ViewChild('limittransaction') limittransaction: ElementRef;
@ViewChild('offsettransaction') offsettransaction: ElementRef;
@ViewChild('limitpower') limitpower: ElementRef;
@ViewChild('offsetpower') offsetpower: ElementRef;
@ViewChild('limitcurrent') limitcurrent: ElementRef;
@ViewChild('offsetcurrent') offsetcurrent: ElementRef;
@ViewChild('limitvoltage') limitvoltage: ElementRef;
@ViewChild('offsetvoltage') offsetvoltage: ElementRef;
@ViewChild('limitfulltamper') limitfulltamper: ElementRef;
@ViewChild('offsetfulltamper') offsetfulltamper: ElementRef;

dataDownload(number){
  this.isLoading = true;
 
//alert(number+" "+this.aD.reporting.value);
  this.message ='';
  this.message2 ='';
  this.message3 ='';
  this.message4 ='';
  this.message5 ='';
  this.message6 ='';
  this.message7 ='';
  this.message8 ='';
  this.message9 ='';
  this.message10 ='';
  this.message11 ='';
  this.message12 ='';
  this.message13 =''; 
  this.message14 =''; 
  this.message15 =''; 
  this.message16 =''; 
  this.message17 =''; 
  this.message18 =''; 
  this.message19 =''; 
  this.message20 =''; 

  this.message28 =''; 
  this.message29 =''; 
  this.message30 =''; 
  this.message31 =''; 
  this.message32 =''; 
  this.message33 =''; 
  this.message34 =''; 

  this.AddProcess = true;
  
  let body = new URLSearchParams();
  body.set('subdivId',this.currentUser.data.userData.subdivision);
  body.set('dcuId', this.currentUser.data.userData.dcu);
  body.set('meterType', this.meterDetail.meterType);
  body.set('conAccId', this.meterDetail.cACNO);
  body.set('meterId', this.meterDetail.muID);
  body.set('reporting', this.aD.reporting.value);
    if (this.aD.reporting.value) 
    {
      if(this.aD.reporting.value=='1')
      {
          this.isLoading2 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      if(this.aD.reporting.value=='26')
      {
          this.isLoading20 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      if(this.aD.reporting.value=='20')
      {
          this.isLoading5 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      if(this.aD.reporting.value=='19')
      {
          this.isLoading4 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='5')
      {
        //alert(this.aD.offSetBilling.value);
        this.isLoading3 = true;
        if(this.aD.offSetBilling.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetBilling.value!=''){
          if(this.aD.offSetBilling.value==''){
            alert("Please Enter Billing Offset Value");
            this.offsetbilling.nativeElement.focus();
          }
          /*if(this.aD.limitBilling.value==''){
            alert("Please Enter Billing limit Value");
            this.limitbilling.nativeElement.focus();
          }*/
          body.set('offSet', this.aD.offSetBilling.value);
          body.set('limit', '0');
        }
      }
      else if(this.aD.reporting.value=='25'){
        this.isLoading6 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='28'){
        this.isLoading28 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='29'){
        this.isLoading29 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='30'){
        this.isLoading30 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='31'){
        this.isLoading31 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='32'){
        this.isLoading32 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='33'){
        this.isLoading33 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='34'){
        this.isLoading34 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='27'){
        this.isLoading27 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='12'){
        this.isLoading7 = true;
        if(this.aD.offSetVoltage.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetVoltage.value!=''){
          body.set('offSet', this.aD.offSetVoltage.value);
          body.set('limit', "10");
        }
      }
      else if(this.aD.reporting.value=='13'){
        this.isLoading8 = true;
        if(this.aD.offSetCurrent.value=='' ){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetCurrent.value!=''){
          body.set('offSet', this.aD.offSetCurrent.value);
          body.set('limit', "10");
        }
      }
      else if(this.aD.reporting.value=='14'){
        this.isLoading9 = true;
        if(this.aD.offSetPower.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetPower.value!=''){
          body.set('offSet', this.aD.offSetPower.value);
          body.set('limit', "10");
        }
      }
      else if(this.aD.reporting.value=='15'){
        this.isLoading10 = true;
        if(this.aD.offSetTransactional.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetTransactional.value!=''){
          body.set('offSet', this.aD.offSetTransactional.value);
          body.set('limit', "10");
        }
      }
      else if(this.aD.reporting.value=='16'){
        this.isLoading11 = true;
        if(this.aD.offSetOther.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetOther.value!=''){
          body.set('offSet', this.aD.offSetOther.value);
          body.set('limit', "10");
        }
      }
      else if(this.aD.reporting.value=='17'){
        this.isLoading12 = true;
        if(this.aD.offSetCoverOpen.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetCoverOpen.value!=''){
          body.set('offSet', this.aD.offSetCoverOpen.value);
          body.set('limit', "10");
        }
      }
      else if(this.aD.reporting.value=='18'){
        this.isLoading13 = true;
        if(this.aD.offCurrentEvent.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offCurrentEvent.value!=''){
          body.set('offSet', this.aD.offCurrentEvent.value);
          body.set('limit', "10");
        }
      }
      else if(this.aD.reporting.value=='19'){
        this.isLoading4 = true;
        if(this.aD.offSetBlockLoadSurvey.value=='' && this.aD.limitBlockLoadSurvey.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetBlockLoadSurvey.value!='' || this.aD.limitBlockLoadSurvey.value!=''){
          if(this.aD.offSetBlockLoadSurvey.value==''){
            alert("Please Enter Block Load Survey Offset Value");
            this.offsetblockload.nativeElement.focus();
          }
          if(this.aD.limitBlockLoadSurvey.value==''){
            alert("Please Enter Block Load Survey limit Value");
            this.limitblockload.nativeElement.focus();
          }
          body.set('offSet', this.aD.offSetBlockLoadSurvey.value);
          body.set('limit', this.aD.limitBlockLoadSurvey.value);
        }
      }
      else if(this.aD.reporting.value=='20'){
        this.isLoading5 = true; 
        if(this.aD.offSetAllLoadSurvey.value=='' && this.aD.limiAllLoadSurveye.value==''){
          body.set('offSet','');
          body.set('limit','');
        }else if(this.aD.offSetAllLoadSurvey.value!='' || this.aD.limiAllLoadSurveye.value!=''){
          if(this.aD.offSetAllLoadSurvey.value==''){
            alert("Please Enter All Load Survey Offset Value");
            this.offsetallloadsurvey.nativeElement.focus();
          }
          if(this.aD.limiAllLoadSurveye.value==''){
            alert("Please Enter All Load Survey limit Value");
            this.limitallloadsurvey.nativeElement.focus();
          }
        }
        body.set('offSet', this.aD.offSetAllLoadSurvey.value);
        body.set('limit', this.aD.limiAllLoadSurveye.value);
      }
      else if(this.aD.reporting.value=='21')
      {
          this.isLoading15 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='22')
      {
          this.isLoading16 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='23')
      {
          this.isLoading17 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else if(this.aD.reporting.value=='24')
      {
          this.isLoading18 = true;
          body.set('offSet','');
          body.set('limit','');
      } 
      else if(this.aD.reporting.value=='26')
      {
          this.isLoading20 = true;
          body.set('offSet','');
          body.set('limit','');
      } 
      else if(this.aD.reporting.value=='68')
      {
          this.isLoading19 = true;
          body.set('offSet','');
          body.set('limit','');
      }
      else{
        body.set('offSet','');
        body.set('limit','');
      }
        if(this.meterDetail.srNo){
          body.set('cmdFrom', '1');
        }else{
          body.set('cmdFrom', '0');
        }
        if(this.currentUser.data.userData.subdivision=='109'){
          body.set('commMode', '1');
        }else{
          body.set('commMode', this.meterDetail.commMode);
        }
        body.set('dgFreeUnits', this.meterDetail.dgFreeUnits);
        body.set('authToken', this.currentUser.authToken);
        body.set('sdate', this.startDate.toString());
        body.set('edate', this.endDate.toString());
        this.genReqs.postReq('/addMeterReportingDesh', body).subscribe((result)=>{
          this.responseData = result;
            this.AddProcess = false;
            this.isLoading = false;
            this.isLoading2 = false;
            this.isLoading3 = false;
            this.isLoading4 = false;
            this.isLoading5 = false;
            this.isLoading6 = false;
            this.isLoading7 = false;
            this.isLoading8 = false;
            this.isLoading9 = false;
            this.isLoading10 = false;
            this.isLoading11 = false;
            this.isLoading12 = false;
            this.isLoading13 = false;
            this.isLoading14 = false;
            this.isLoading15 = false;
            this.isLoading16 = false;
            this.isLoading17 = false;
            this.isLoading18 = false;
            this.isLoading19 = false;
            this.isLoading20 = false;
            this.isLoading28 = false;
            this.isLoading29 = false;
            this.isLoading30 = false;
            this.isLoading31 = false;
            this.isLoading32 = false;
            this.isLoading33 = false;
            this.isLoading34 = false;
            if (this.responseData.success) {
              console.log("--------------"+this.aD.reporting.value);
              this.thumb = true;
              if(this.aD.reporting.value=='26')
              {
                this.isLoading20 = false;
                this.message20 = this.responseData.data.message;
                if(this.message20==''){
                  this.message20 ="Communication Error !";
                }
                if(this.message20.includes("Not") || 
                this.message20.includes("not available") || 
                this.message20.includes("not updated") || 
                this.message20.includes("You are not authorize") || 
                this.message20.includes("Communication Failure") || 
                this.message20.includes("Un-Successfully")|| 
                this.message20.includes("Unsuccessfully") || 
               
                this.message20.includes("Error") || 
                this.message20.includes("Already")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='1')
              {
                this.isLoading2 = false;
                this.message2 = this.responseData.data.message;
                if(this.message2==''){
                  this.message2 ="Communication Error !";
                }
                if(this.message2.includes("Not") ||
                this.message2.includes("not available") || 
                this.message2.includes("not updated") || 
                this.message2.includes("You are not authorize") || 
                this.message2.includes("Communication Failure") || 
                this.message2.includes("Un-Successfully")|| 
                this.message2.includes("Unsuccessfully") || 
                
                this.message2.includes("Error")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='5')
              {
                this.isLoading3 = false;
                this.message3 = this.responseData.data.message;
                if(this.message3==''){
                  this.message3 ="Communication Error !";
                }
                if(this.message3.includes("Not") || 
                this.message3.includes("not available") || 
                this.message3.includes("not updated") || 
                this.message3.includes("You are not authorize") || 
                this.message3.includes("Communication Failure") ||
                this.message3.includes("Un-Successfully")|| 
                this.message3.includes("Unsuccessfully") || 
                
                this.message3.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='20')
              {
                this.isLoading5 = false;
                this.message5 = this.responseData.data.message;
                if(this.message5==''){
                  this.message5 ="Communication Error !";
                }
                if(this.message5.includes("Not") || 
                this.message5.includes("not available") || 
                this.message5.includes("not updated") || 
                this.message5.includes("You are not authorize") || 
                this.message5.includes("Communication Failure") ||
                this.message5.includes("Un-Successfully")|| 
                this.message5.includes("Unsuccessfully") || 
                
                this.message5.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='19')
              {
                this.isLoading4 = false;
                this.message4 = this.responseData.data.message;
                if(this.message4==''){
                  this.message4 ="Communication Error !";
                }
                if(this.message4.includes("Not") || 
                this.message4.includes("not available") || 
                this.message4.includes("not updated") || 
                this.message4.includes("You are not authorize") || 
                this.message4.includes("Communication Failure") ||
                this.message4.includes("Un-Successfully")|| 
                this.message4.includes("Unsuccessfully") || 
               
                this.message4.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='25')
              {
                this.isLoading6 = false;
                this.message6 = this.responseData.data.message;
                if(this.message6==''){
                  this.message6 ="Communication Error !";
                }
                if(this.message6.includes("Not") || 
                this.message6.includes("not available") || 
                this.message6.includes("not updated") || 
                this.message6.includes("You are not authorize") || 
                this.message6.includes("Communication Failure") ||
                this.message6.includes("Un-Successfully")|| 
                this.message6.includes("Unsuccessfully") || 
                
                this.message6.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='28')
                {
                  this.isLoading28 = false;
                  this.message28 = this.responseData.data.message;
                  if(this.message28==''){
                    this.message28 ="Communication Error !";
                  }
                  if(this.message28.includes("Not") || 
                  this.message28.includes("not available") || 
                  this.message28.includes("not updated") || 
                  this.message28.includes("You are not authorize") || 
                  this.message28.includes("Communication Failure") ||
                  this.message28.includes("Un-Successfully")|| 
                  this.message28.includes("Unsuccessfully") || 
                  
                  this.message28.includes("Error")
                  ){
                    this.thumb = false;
                  }else{
                    this.thumb = true;
                  }
                }
                if(this.aD.reporting.value=='29')
                  {
                    this.isLoading29 = false;
                    this.message29 = this.responseData.data.message;
                    if(this.message29==''){
                      this.message29 ="Communication Error !";
                    }
                    if(this.message29.includes("Not") || 
                    this.message29.includes("not available") || 
                    this.message29.includes("not updated") || 
                    this.message29.includes("You are not authorize") || 
                    this.message29.includes("Communication Failure") ||
                    this.message29.includes("Un-Successfully")|| 
                    this.message29.includes("Unsuccessfully") || 
                    
                    this.message29.includes("Error")
                    ){
                      this.thumb = false;
                    }else{
                      this.thumb = true;
                    }
                  }
                  if(this.aD.reporting.value=='30')
                    {
                      this.isLoading30 = false;
                      this.message30 = this.responseData.data.message;
                      if(this.message30==''){
                        this.message30 ="Communication Error !";
                      }
                      if(this.message30.includes("Not") || 
                      this.message30.includes("not available") || 
                      this.message30.includes("not updated") || 
                      this.message30.includes("You are not authorize") || 
                      this.message30.includes("Communication Failure") ||
                      this.message30.includes("Un-Successfully")|| 
                      this.message30.includes("Unsuccessfully") || 
                      
                      this.message30.includes("Error")
                      ){
                        this.thumb = false;
                      }else{
                        this.thumb = true;
                      }
                    }
                    if(this.aD.reporting.value=='31')
                      {
                        this.isLoading31 = false;
                        this.message31 = this.responseData.data.message;
                        if(this.message31==''){
                          this.message31 ="Communication Error !";
                        }
                        if(this.message31.includes("Not") || 
                        this.message31.includes("not available") || 
                        this.message31.includes("not updated") || 
                        this.message31.includes("You are not authorize") || 
                        this.message31.includes("Communication Failure") ||
                        this.message31.includes("Un-Successfully")|| 
                        this.message31.includes("Unsuccessfully") || 
                        
                        this.message31.includes("Error")
                        ){
                          this.thumb = false;
                        }else{
                          this.thumb = true;
                        }
                      }
                      if(this.aD.reporting.value=='32')
                        {
                          this.isLoading32 = false;
                          this.message32 = this.responseData.data.message;
                          if(this.message32==''){
                            this.message32 ="Communication Error !";
                          }
                          if(this.message32.includes("Not") || 
                          this.message32.includes("not available") || 
                          this.message32.includes("not updated") || 
                          this.message32.includes("You are not authorize") || 
                          this.message32.includes("Communication Failure") ||
                          this.message32.includes("Un-Successfully")|| 
                          this.message32.includes("Unsuccessfully") || 
                          
                          this.message32.includes("Error")
                          ){
                            this.thumb = false;
                          }else{
                            this.thumb = true;
                          }
                        }

                        if(this.aD.reporting.value=='33')
                          {
                            this.isLoading33 = false;
                            this.message33 = this.responseData.data.message;
                            if(this.message33==''){
                              this.message33 ="Communication Error !";
                            }
                            if(this.message33.includes("Not") || 
                            this.message33.includes("not available") || 
                            this.message33.includes("not updated") || 
                            this.message33.includes("You are not authorize") || 
                            this.message33.includes("Communication Failure") ||
                            this.message33.includes("Un-Successfully")|| 
                            this.message33.includes("Unsuccessfully") || 
                            
                            this.message33.includes("Error")
                            ){
                              this.thumb = false;
                            }else{
                              this.thumb = true;
                            }
                          }
                          if(this.aD.reporting.value=='34')
                            {
                              this.isLoading34 = false;
                              this.message34 = this.responseData.data.message;
                              if(this.message34==''){
                                this.message34 ="Communication Error !";
                              }
                              if(this.message34.includes("Not") || 
                              this.message34.includes("not available") || 
                              this.message34.includes("not updated") || 
                              this.message34.includes("You are not authorize") || 
                              this.message34.includes("Communication Failure") ||
                              this.message34.includes("Un-Successfully")|| 
                              this.message34.includes("Unsuccessfully") || 
                              
                              this.message34.includes("Error")
                              ){
                                this.thumb = false;
                              }else{
                                this.thumb = true;
                              }
                            }
                      
              if(this.aD.reporting.value=='27')
                {
                  this.isLoading27 = false;
                  this.message27 = this.responseData.data.message;
                  if(this.message27==''){
                    this.message27 ="Communication Error !";
                  }
                  if(this.message27.includes("Not") || 
                  this.message27.includes("not available") || 
                  this.message27.includes("not updated") || 
                  this.message27.includes("You are not authorize") || 
                  this.message27.includes("Communication Failure") ||
                  this.message27.includes("Un-Successfully")|| 
                  this.message27.includes("Unsuccessfully") || 
                 
                  this.message27.includes("Error")
                  ){
                    this.thumb = false;
                  }else{
                    this.thumb = true;
                  }
                }
              if(this.aD.reporting.value=='12')
              {
                this.isLoading7 = false;
                this.message7 = this.responseData.data.message;
                if(this.message7==''){
                  this.message7 ="Communication Error !";
                }
                if(this.message7.includes("Not") || 
                this.message7.includes("not available") || 
                this.message7.includes("not updated") || 
                this.message7.includes("You are not authorize") || 
                this.message7.includes("Communication Failure") ||
                this.message7.includes("Un-Successfully")|| 
                this.message7.includes("Unsuccessfully") || 
               
                this.message7.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='13')
              {
                this.isLoading8 = false;
                this.message8 = this.responseData.data.message;
                if(this.message8==''){
                  this.message8 ="Communication Error !";
                }
                if(this.message8.includes("Not") || 
                this.message8.includes("not available") || 
                this.message8.includes("not updated") || 
                this.message8.includes("You are not authorize") || 
                this.message8.includes("Communication Failure") ||
                this.message8.includes("Un-Successfully")|| 
                this.message8.includes("Unsuccessfully") || 
               
                this.message8.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='14')
              {
                this.isLoading9 = false;
                this.message9 = this.responseData.data.message;
                if(this.message9==''){
                  this.message9 ="Communication Error !";
                }
                if(this.message9.includes("Not") || 
                this.message9.includes("not available") || 
                this.message9.includes("not updated") || 
                this.message9.includes("You are not authorize") || 
                this.message9.includes("Communication Failure") ||
                this.message9.includes("Un-Successfully")|| 
                this.message9.includes("Unsuccessfully") || 
               
                this.message9.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='15')
              {
                this.isLoading10 = false;
                this.message10 = this.responseData.data.message;
                if(this.message10==''){
                  this.message10 ="Communication Error !";
                }
                if(this.message10.includes("Not") || 
                this.message10.includes("not available") || 
                this.message10.includes("not updated") || 
                this.message10.includes("You are not authorize") || 
                this.message10.includes("Communication Failure") ||
                this.message10.includes("Un-Successfully")|| 
                this.message10.includes("Unsuccessfully") || 
               
                this.message10.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='16')
              {
                this.isLoading11 = false;
                this.message11 = this.responseData.data.message;
                if(this.message11==''){
                  this.message11 ="Communication Error !";
                }
                if(this.message11.includes("Not") || 
                this.message11.includes("not available") || 
                this.message11.includes("not updated") || 
                this.message11.includes("You are not authorize") || 
                this.message11.includes("Communication Failure") ||
                this.message11.includes("Un-Successfully")|| 
                this.message11.includes("Unsuccessfully") || 
               
                this.message11.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='17')
              {
                this.isLoading12 = false;
                this.message12 = this.responseData.data.message;
                if(this.message12==''){
                  this.message12 ="Communication Error !";
                }
                if(this.message12.includes("Not") || 
                this.message12.includes("not available") || 
                this.message12.includes("not updated") || 
                this.message12.includes("You are not authorize") || 
                this.message12.includes("Communication Failure") ||
                this.message12.includes("Un-Successfully")|| 
                this.message12.includes("Unsuccessfully") || 
                
                this.message12.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='18')
              {
                this.isLoading13 = false;
                this.message13 = this.responseData.data.message;
                if(this.message13==''){
                  this.message13 ="Communication Error !";
                }
                if(this.message13.includes("Not") || 
                this.message13.includes("not available") || 
                this.message13.includes("not updated") || 
                this.message13.includes("You are not authorize") || 
                this.message13.includes("Communication Failure") ||
                this.message13.includes("Un-Successfully")|| 
                this.message13.includes("Unsuccessfully") || 
               
                this.message13.includes("Error")
                ){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='21')
              {
                this.isLoading15 = false;
                this.message15 = this.responseData.data.message;
                if(this.message15==''){
                  this.message15 ="Communication Error !";
                }
                if(this.message15.includes("Not") || 
                this.message15.includes("not available") || 
                this.message15.includes("not updated") || 
                this.message15.includes("You are not authorize") || 
                this.message15.includes("Communication Failure") || 
                this.message15.includes("Un-Successfully")|| 
                this.message15.includes("Unsuccessfully") || 
                
                this.message15.includes("Error")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='22')
              {
                this.isLoading16 = false;
                this.message16 = this.responseData.data.message;
                if(this.message16==''){
                  this.message16 ="Communication Error !";
                }
                if(this.message16.includes("Not") || 
                this.message16.includes("not available") || 
                this.message16.includes("not updated") || 
                this.message16.includes("You are not authorize") || 
                this.message16.includes("Communication Failure") || 
                this.message16.includes("Un-Successfully")|| 
                this.message16.includes("Unsuccessfully") || 
                
                this.message16.includes("Error")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='23')
              {
                this.isLoading17 = false;
                this.message17 = this.responseData.data.message;
                if(this.message17==''){
                  this.message17 ="Communication Error !";
                }
                if(this.message17.includes("Not") || 
                this.message17.includes("not available") || 
                this.message17.includes("not updated") || 
                this.message17.includes("You are not authorize") || 
                this.message17.includes("Communication Failure") || 
                this.message17.includes("Un-Successfully")|| 
                this.message17.includes("Unsuccessfully") || 
                
                this.message17.includes("Error")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='24')
              {
                this.isLoading18 = false;
                this.message18 = this.responseData.data.message;
                if(this.message18==''){
                  this.message18 ="Communication Error !";
                }
                if(this.message18.includes("Not") || 
                this.message18.includes("not available") || 
                this.message18.includes("not updated") || 
                this.message18.includes("You are not authorize") || 
                this.message18.includes("Communication Failure") || 
                this.message18.includes("Un-Successfully")|| 
                this.message18.includes("Unsuccessfully") || 
                
                this.message18.includes("Error")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              if(this.aD.reporting.value=='68')
              {
                this.isLoading19 = false;
                this.message19 = this.responseData.data.message;
                if(this.message19==''){
                  this.message19 ="Communication Error !";
                }
                if(this.message19.includes("Not") || 
                this.message19.includes("not available") || 
                this.message19.includes("not updated") || 
                this.message19.includes("You are not authorize") || 
                this.message19.includes("Communication Failure") || 
                this.message19.includes("Un-Successfully")|| 
                this.message19.includes("Unsuccessfully") || 
                
                this.message19.includes("Error")){
                  this.thumb = false;
                }else{
                  this.thumb = true;
                }
              }
              //this.modal.close('done');
            }
        },(err)=>{
          if (err.status === 401) {
            this.authenticationService.logout();
          }
          this.thumb = false;
          this.isLoading = false;
          if(this.aD.reporting.value=='1')
              {
                this.isLoading2 = false;
                this.message2 ="Communication Error !";
              }
              if(this.aD.reporting.value=='5')
              {
                this.isLoading3 = false;
                this.message3 ="Communication Error !";
              }
              if(this.aD.reporting.value=='20')
              {
                this.isLoading5 = false;
                this.message5 ="Communication Error !";
              }
              if(this.aD.reporting.value=='19')
              {
                this.isLoading4 = false;
                this.message4 ="Communication Error !";
              }
              if(this.aD.reporting.value=='25')
              {
                this.isLoading6 = false;
                this.message6 ="Communication Error !";
                
              }
              if(this.aD.reporting.value=='28')
                {
                  this.isLoading28 = false;
                  this.message28 ="Communication Error !";
                  
                }
                if(this.aD.reporting.value=='29')
                  {
                    this.isLoading29= false;
                    this.message29 ="Communication Error !";
                    
                  }
                  if(this.aD.reporting.value=='30')
                    {
                      this.isLoading30 = false;
                      this.message30 ="Communication Error !";
                      
                    }
                    if(this.aD.reporting.value=='31')
                      {
                        this.isLoading31 = false;
                        this.message31="Communication Error !";
                        
                      }
                      if(this.aD.reporting.value=='32')
                        {
                          this.isLoading32 = false;
                          this.message32 ="Communication Error !";
                          
                        }
                        if(this.aD.reporting.value=='33')
                          {
                            this.isLoading33 = false;
                            this.message33 ="Communication Error !";
                            
                          }
                          if(this.aD.reporting.value=='34')
                            {
                              this.isLoading34 = false;
                              this.message34 ="Communication Error !";
                              
                            }
              if(this.aD.reporting.value=='27')
                {
                  this.isLoading27 = false;
                  this.message27 ="Communication Error !";
                  
              }
              if(this.aD.reporting.value=='12')
              {
                this.isLoading7 = false;
                this.message7 ="Communication Error !";
              }
              if(this.aD.reporting.value=='13')
              {
                this.isLoading8 = false;
                this.message8 ="Communication Error !";
              }
              if(this.aD.reporting.value=='14')
              {
                this.isLoading9 = false;
                this.message9 ="Communication Error !";
              }
              if(this.aD.reporting.value=='15')
              {
                this.isLoading10 = false;
                this.message10 ="Communication Error !";
              }
              if(this.aD.reporting.value=='16')
              {
                this.isLoading11 = false;
                this.message11 ="Communication Error !";
              }
              if(this.aD.reporting.value=='17')
              {
                this.isLoading12 = false;
                this.message12 ="Communication Error !";
              }
              if(this.aD.reporting.value=='18')
              {
                this.isLoading13 = false;
                this.message13 ="Communication Error !";
                
              }
              
              if(this.aD.reporting.value=='21')
              {
                this.isLoading15 = false;
                this.message15 ="Communication Error !";
              }
              
              if(this.aD.reporting.value=='22')
              {
                this.isLoading16 = false;
                this.message16 ="Communication Error !";
              }
              if(this.aD.reporting.value=='23')
              {
                this.isLoading17 = false;
                this.message17 ="Communication Error !";
              }
              if(this.aD.reporting.value=='24')
              {
                this.isLoading18 = false;
                this.message18 ="Communication Error !";
              }
              if(this.aD.reporting.value=='68')
              {
                this.isLoading19 = false;
                this.message19 ="Communication Error !";
              }
          this.message = 'Unable to process yours request!';
        });
      
    }
  else
  {
    alert("Please select at least one option to proceed further!");
  }
}

}

