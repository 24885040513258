import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { ModalService } from '../../_services/modal.service';
import { generalRequestsService, AuthenticationService } from 'src/app/_services';
import { User } from 'src/app/_models';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Access } from 'src/app/_models/access';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from 'src/app/deshboardauthall/TableUtil';

@Component({
  selector: 'app-consscomplaint',
  templateUrl: './consscomplaint.component.html',
  styleUrls: ['./consscomplaint.component.scss']
})
export class ConsscomplaintComponent implements OnInit {
  divClass: any="tblH_pagination";
  loadingNoData: boolean = false;
  messageResult: any;
  length=0;
  levelSearch: string = '';
  faSpinner = faSpinner;
  loading: boolean = true;
  selectedSubDivision: any;

  responseData: any;
  currentUserSubscription: Subscription;
  currentUser: User;
  enableSocietyChange: boolean = true;
  dataSource: any = new MatTableDataSource([]);
  loadingMessage: any = '';
  displayedColumns: string[] = [
    'consFullName',
    'complaintType',
    'complaintStatus',
    'creationDateTime',
    'edit',
  ];

  levelHeadsCols = [];

  levelColumnsToDisplay = [];
  //pagination settings
  limit: number = 10;
  skip: number = 0;
  filter = '';
  totalLength: number = 0;
  pageSize: number = 7;
  pageSizeOptions: number[] = [7, 14, 21];
  allLevelTotalRecord: number = 0;
  getThrough: Access;
  removeProcess: boolean = false;
  responseMessage: string = '';
  constructor(
    private modalService: ModalService,
    private genReqs: generalRequestsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    if (this.currentUser.data.userData.authLevel == '7') {
      this.enableSocietyChange = false;
    }
    this.authenticationService.getThrough.subscribe(nav => this.getThrough = nav);
  }

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  ngOnInit() {
    if (!this.getThrough.authority.Consumer_Sub_Edit_Consumer_Complaint_Button && !this.getThrough.authority.Consumer_Sub_Delete_Consumer_Complaint_Button) {
      this.levelHeadsCols = [
        { label: "Sr. No.",name:"srNo", checked: true },
        { label: "Flat No", name: "consFlatNo", checked: true },
        { label: "Consumer Name", name: "consFullName", checked: true },
        { label: "Complaint Type", name: "complaintType", checked: true },
        { label: "Complaint Status ", name: "complaintStatus", checked: true },
        { label: "Remark", name: "complaintRemark", checked: true },
        { label: "Date & Time", name: "creationDateTime", checked: true },

      ];

      this.levelColumnsToDisplay = [
        `srNo`,
        `consFlatNo`,
        'consFullName',
        'complaintType',
        'complaintStatus',
        'complaintRemark',
        'creationDateTime'
      ];
    } else {
      this.levelHeadsCols = [
        { label: "Sr. No.",name:"srNo", checked: true },
        { label: "Flat No", name: "consFlatNo", checked: true },
        { label: "Consumer Name", name: "consFullName", checked: true },
        { label: "Complaint Type", name: "complaintType", checked: true },
        { label: "Complaint Status ", name: "complaintStatus", checked: true },
        { label: "Remark", name: "complaintRemark", checked: true },
        { label: "Date & Time", name: "creationDateTime", checked: true },
        { label: "Edit", name: "edit", checked: true },
      ];

      this.levelColumnsToDisplay = [
        `srNo`,
        'consFlatNo',
        'consFullName',
        'complaintType',
        'complaintStatus',
        'complaintRemark',
        'creationDateTime',
        'edit'
      ];
    }
    this.getComaintListing();
    if (this.enableSocietyChange) {
      this.whichSubDomain();
    }
  }

  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  whichSubDomain() {
    this.modalService.popautocomplete().pipe(
      take(1)).subscribe(result => {
        this.selectedSubDivision = result;
        //console.log('selected val', this.selectedSubDivision);
        this.getComaintListing();
      }
      );
  }

  onLevelSearchChange(search) {
    this.levelSearch = search;
    this.skip = 0;
    this.getComaint(false);
  }

  getComaint(addNew: boolean = false) {
    let body = new URLSearchParams();
    if (this.enableSocietyChange) {
      body.set('subdivId', this.selectedSubDivision);
    } else {
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }
    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.levelSearch);
    body.set('consId', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getConsComplaint', body).subscribe((result) => {
      this.responseData = result;
      if (this.responseData.success) {
        this.dataSource = this.responseData.data;
        //console.log();
        this.length = this.responseData.datalimit.totalResult;
        if(this.length==0){
          this.loadingNoData=true;
          this.divClass="tblH_norecord";
        }
        if(this.length<10){
          this.limit= this.length;
        }

      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loadingMessage = 'Unable to process yours request!';
    });
  }

  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable","Consumer Complaints Data");
  }


  updateLevelChange(event, index, item) {
    item.checked = !item.checked;
    this.levelColumnsToDisplay = [];
    for (let col of this.levelHeadsCols) {
      if (col.checked) {
        this.levelColumnsToDisplay.push(col.name.toString());
      }
    }
  }

  getComaintListing() {
    this.loading = true;
    let body = new URLSearchParams();
    if (this.enableSocietyChange) {
      body.set('subdivId', this.selectedSubDivision);
    } else {
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }


    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('consId', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getConsComplaint', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      //console.log(this.responseData);
      if (this.responseData.success) {
        setTimeout(() => {
          this.dataSource = this.responseData.data;
          this.length = this.responseData.datalimit.totalResult;
          if(this.length==0){
            this.loadingNoData=true;
            this.divClass="tblH_norecord";
          }
          if(this.length<10){
            this.limit= this.length;
          }

        });
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }
  
  refreshData() {
    this.limit=20;
    this.loading = true;
    let body = new URLSearchParams();
    if (this.enableSocietyChange) {
      body.set('subdivId', this.selectedSubDivision);
    } else {
      body.set('subdivId', this.currentUser.data.userData.subdivision);
    }


    body.set('offSet', this.skip.toString());
    body.set('limit', this.limit.toString());
    body.set('filter', this.filter);
    body.set('consId', '0');
    body.set('authToken', this.currentUser.authToken);
    this.genReqs.postReq('/getConsComplaint', body).subscribe((result) => {
      this.responseData = result;
      this.loading = false;
      //console.log(this.responseData);
      if (this.responseData.success) {
        setTimeout(() => {
          this.dataSource = this.responseData.data;
          this.length = this.responseData.datalimit.totalResult;
          if(this.length==0){
            this.loadingNoData=true;
            this.divClass="tblH_norecord";
          }
          if(this.length<10){
            this.limit= this.length;
          }

        });
      } else {
        this.loadingMessage = this.responseData.message;//this.responseData.isresponse;
      }
    }, (err) => {
      if (err.status === 401) {
        this.authenticationService.logout();
      }
      this.loading = false;
      this.loadingMessage = 'Unable to process yours request!';
    });
  }
  addconsumercomplaint() {
    this.modalService.addConsComplaint().pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.refreshData();
      }
      //console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  editconsumercomplaint(row) {
    this.modalService.editConsComplaint(row).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
      if(result.toString()!='Cancel'){
        this.refreshData();
      }
     // console.log({ messageResult: result });
      this.messageResult = result;
    });
  }

  removelevel(row) {
    //deleteAuth
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to remove complaint id ' + row.complaintUniqueId + ' ?')
      .then((confirmed) => {
        if (confirmed) {
          this.removeProcess = true;
          let body = new URLSearchParams();
          body.set('levelId', row.complaintUniqueId);
          //body.set('subDivId', row.subdivId);
          if (this.enableSocietyChange) {
            body.set('subDivId', this.selectedSubDivision);
          } else {
            body.set('subDivId', this.currentUser.data.userData.subdivision);
          }

          body.set('authToken', this.currentUser.authToken);
          this.genReqs.postReq('/deleteComplaint', body).subscribe((result) => {
            this.responseData = result;
            this.removeProcess = false;
            this.responseMessage = this.responseData.message;
            this.getComaintListing();
          }, (err) => {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            this.responseMessage = 'Unable to process yours request!';
          });
        }
      })
      .catch(() => console.log('no response or closed'));
  }

  changeMeterPage(event) {
    if (event.pageSize !== this.limit) {
      this.limit = event.pageSize;
      this.skip = event.pageSize * event.pageIndex;
      this.getComaintListing();
    } else {
      this.skip = event.pageSize * event.pageIndex;
      this.getComaintListing();
    }
  }

  getPageSizeOptions() {
    if (this.length > 0 && this.length <= 10) {
      return [this.length];
    } else if (this.length > 10 && this.length <= 20) {
      return [10, this.length];
    } else if (this.length > 20 && this.length <= 40) {
      return [10, 20, this.length];
    } else if (this.length > 40 && this.length <= 80) {
      return [10, 20, 40, this.length];
    } else if (this.length > 80 && this.length <= 160) {
      return [10, 20, 40, 80, this.length];
    } else if (this.length > 160 && this.length <= 320) {
      return [10, 20, 40, 80, 160, this.length];
    } else if (this.length > 320) {
      return [10, 20, 40, 80, 160, 320, this.length];
    } 
  }
}
